import { Clusterer, Map, Placemark, YMaps } from '@pbe/react-yandex-maps'
import { useCallback, useEffect, useRef, useState } from 'react'
import { TfiMinus, TfiPlus } from 'react-icons/tfi'

import markerUrl from '../../../assets/images/new-builds/marker.svg'
import { getCityCoordinates } from '../../../helpers/placeMap'
import { useProjects } from '../../../hooks/useProjects'
import styles from '../../Main/newBuildings/newBuildings.module.scss'
import { ProjectsData } from '../../Main/newBuildings/NewBuildings.types'
import { MapPopupCard } from './components/MapPopupCard'
import { MapState, PlaceProps } from './PlaceMap.types'

export const PlacesMap = ({ mapState, city }: PlaceProps) => {
  const [zoom, setZoom] = useState(10)
  const [mapStateNew, setMapState] = useState<MapState>({
    center: [55.751574, 37.573856],
    zoom: zoom,
    controls: [],
  })

  const { items, mapPopupState, handlePlacemarkClick, handleCloseMapPopup } =
    useProjects()

  const map = useRef<any>(null)

  const handleZoomChange = useCallback((event: any) => {
    setZoom(event.get('newZoom'))
  }, [])

  const handleZoomIn = useCallback(() => {
    setZoom(prevZoom => prevZoom + 1)
  }, [])

  const handleZoomOut = useCallback(() => {
    setZoom(prevZoom => prevZoom - 1)
  }, [])

  useEffect(() => {
    setMapState({
      center: getCityCoordinates(city) as number[],
      zoom: zoom,
    })
  }, [city])

  useEffect(() => {
    if (map.current) {
      map.current.setZoom(zoom, { duration: 300 })
    }
  }, [zoom])

  return (
    <div
      className={`${styles.projectsMap} ${mapState ? '' : 'is-hidden'}`}
      id='map'
    >
      <YMaps>
        <Map
          instanceRef={map}
          style={{ width: '100%', height: '100%' }}
          width={'100%'}
          height={'100%'}
          state={mapStateNew}
          options={{ suppressMapOpenBlock: true }}
          onBoundsChange={handleZoomChange}
        >
          <Clusterer
            options={{
              preset: 'islands#blackClusterIcons',
              margin: 2,
              useMapMargin: true,
              zoomMargin: 100,
            }}
          >
            {items?.map((item: ProjectsData) => {
              return (
                <Placemark
                  key={item.LOCATION.ID}
                  geometry={[
                    item.LOCATION.UF_LATITUDE,
                    item.LOCATION.UF_LONGITUDE,
                  ]}
                  options={{
                    iconLayout: 'default#image',
                    iconImageSize: [40, 50],
                    iconImageHref: markerUrl,
                  }}
                  // TODO вынести обькт в функцию, надо держать jsx чистым
                  onClick={() =>
                    handlePlacemarkClick({
                      id: item.LOCATION.ID,
                      image: item.UF_IMAGE,
                      address: item.LOCATION.UF_ADDRESS,
                      name: item.UF_BUILDING_NAME,
                      localityName: item.LOCATION.UF_SUB_LOCALITY_NAME,
                      metroName: item.LOCATION.UF_METRO_NAME,
                      cnt: item.CNT,
                      price: item.PRICE_PER_SQUARE,
                      url: item.UF_BLOCKURL,
                      studio: item.studio,
                      rooms1: item.rooms1,
                      rooms2: item.rooms2,
                      rooms3: item.rooms3,
                    })
                  }
                />
              )
            })}
          </Clusterer>
        </Map>
      </YMaps>
      <button className={styles.buttonZoomIn} onClick={handleZoomIn}>
        <TfiPlus />
      </button>
      <button className={styles.buttonZoomOut} onClick={handleZoomOut}>
        <TfiMinus />
      </button>
      <MapPopupCard data={mapPopupState} closePopup={handleCloseMapPopup} />
    </div>
  )
}
