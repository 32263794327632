import React from 'react'

import imgClose from '../../../assets/images/icons/close-btn.svg'
import styles from '../footer.module.scss'
import { HoverBlockTypes } from './types'

export const FooterAdvertisingHover = ({
  closeAdvertising,
}: HoverBlockTypes) => {
  return (
    <div className={styles.advertisingHover}>
      <div className={styles.advertisingHoverClose} onClick={closeAdvertising}>
        <img src={imgClose} alt={''} />
      </div>
      &copy;&nbsp;2023. <br className={styles.forTable} />
      ПАО &laquo;ПИК-специализированный застройщик&raquo;. ОГРН 1027739137084.
      г. Москва, 123242, ул. Баррикадная, д.19, стр.&nbsp;1. Проектные
      декларации{' '}
      <a href={'https://xn--80az8a.xn--d1aqf.xn--p1ai/'} target={'_blank'}>
        на&nbsp;наш.дом.рф
      </a>
      . Любая информация, представленная на&nbsp;данном сайте, носит
      исключительно информационный характер и&nbsp;ни&nbsp;при каких
      не&nbsp;является публичной офертой, определяемой положениями статьи
      437&nbsp;ГК РФ
    </div>
  )
}
