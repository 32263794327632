import { motion } from 'framer-motion'
import React from 'react'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ym from 'react-yandex-metrika'

import selection9 from '../../../assets/images/selection9.jpg'
import { getAnimationFourParams } from '../../../helpers/animations'
import { getSelections } from '../../../store/selections/selectors'
import { Button } from '../../UI/Button/Button'
import { Table } from '../../UI/Table/Table'
import styles from '../FamilyFormat/familyFormat.module.scss'

export const LifeWest = () => {
  const navigate = useNavigate()
  const selections: any = useSelector(getSelections)
  const handleAnalyticClick = (name: string) => {
    ReactGA.ga('send', 'event', 'main', 'collection click', name)
    ym('93597994', 'reachGoal', 'collection click', {
      'collection click': {
        'collection name': name,
      },
    })
    navigate('/collections/9')
  }

  return (
    <motion.div
      {...getAnimationFourParams}
      className={styles.apartmentsWrapper}
      id='#LifeWest'
    >
      <div className={styles.investmentsBlock}>
        <img
          className={styles.investmentsImg}
          src={selection9}
          alt='LifeWest'
        />
        <div className={styles.investmentsDescription}>
          <h3>Жизнь на&nbsp;западе Москвы</h3>
          <p>Жизнь в&nbsp;престижном районе</p>
          <Button
            content='Интересно'
            arrowRight
            onClick={() => handleAnalyticClick('Жизнь на западе Москвы')}
          />
        </div>
      </div>
      <Table
        items={selections[9]?.items}
        position='bottom'
        isSettlement
        isFirstSelection
      />
    </motion.div>
  )
}
