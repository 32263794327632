import { motion } from 'framer-motion'
import React from 'react'

import { getAnimationParams } from '../../helpers/animations'
import styles from './aboutProject.module.scss'
import { PageProps } from './AboutProjects.types'

export const AboutProject = ({ title, text, image, mainPage }: PageProps) => {
  return (
    <div className={styles.aboutProjectWrapper}>
      <motion.div {...getAnimationParams(-700)} className={styles.leftSide}>
        <h1 style={{ whiteSpace: 'pre-wrap' }}>{title}</h1>
        <p
          className={mainPage && styles.mainPageDescription}
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {text}
        </p>
      </motion.div>

      <motion.div className={styles.rightSide} {...getAnimationParams(800)}>
        <img src={image} alt={title} />
      </motion.div>
    </div>
  )
}
