import React from 'react'

import { MapLogo } from '../../icons/MapLogo'
import { CheckBoxButton } from '../CheckBoxButton/CheckBoxButton'
import { RangePicker } from '../RangeSlider/RangePicker'
import styles from './roomsFilter.module.scss'
import { RoomsFilterTypes } from './RoomsFilter.types'

export const RoomsFilter = ({
  map,
  toggleMap,
  filterChange,
}: RoomsFilterTypes) => {
  return (
    <div className={styles.buttonsBlock}>
      <div className={styles.roomsButtons}>
        <CheckBoxButton label='Студия' value='studio' onChange={filterChange} />
        <CheckBoxButton
          label='1'
          value='1'
          onChange={filterChange}
          borderRadius='50%'
          width='48px'
        />
        <CheckBoxButton
          label='2'
          value='2'
          onChange={filterChange}
          borderRadius='50%'
          width='48px'
        />
        <CheckBoxButton
          label='3+'
          value='more'
          onChange={filterChange}
          borderRadius='50%'
          width='48px'
        />
      </div>
      <RangePicker />
      <div className={styles.mapBtn}>
        <button onClick={toggleMap}>
          {!map ? (
            <>
              На карте
              <MapLogo />
            </>
          ) : (
            'Списком'
          )}
        </button>
      </div>
    </div>
  )
}
