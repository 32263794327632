import React from 'react'

interface CarIconTypes {
  width?: string
  height?: string
  style?: {}
}

export const CarIcon = ({ width, height, style }: CarIconTypes) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 11.5L4.19411 5.13142C4.37147 4.18548 5.19742 3.5 6.15985 3.5H17.8401C18.8026 3.5 19.6285 4.18548 19.8059 5.13142L21 11.5C21.5523 11.5 22 11.9477 22 12.5V14.5C22 15.0523 21.5523 15.5 21 15.5V19.5C21 20.0523 20.5523 20.5 20 20.5H18C17.4477 20.5 17 20.0523 17 19.5V17.5H7V19.5C7 20.0523 6.55228 20.5 6 20.5H4C3.44772 20.5 3 20.0523 3 19.5L3 15.5C2.44772 15.5 2 15.0523 2 14.5V12.5C2 11.9477 2.44772 11.5 3 11.5ZM17.0102 5.5C17.4914 5.5 17.9044 5.84274 17.9931 6.31571L18.7431 10.3157C18.8585 10.9311 18.3864 11.5 17.7602 11.5H6.23978C5.61364 11.5 5.14152 10.9311 5.25691 10.3157L6.00691 6.31571C6.09559 5.84274 6.50856 5.5 6.98978 5.5H17.0102ZM5 14.5C5 13.9477 5.44772 13.5 6 13.5H7C7.55228 13.5 8 13.9477 8 14.5C8 15.0523 7.55228 15.5 7 15.5H6C5.44772 15.5 5 15.0523 5 14.5ZM17 13.5C16.4477 13.5 16 13.9477 16 14.5C16 15.0523 16.4477 15.5 17 15.5H18C18.5523 15.5 19 15.0523 19 14.5C19 13.9477 18.5523 13.5 18 13.5H17Z'
        fill='#141414'
      />
    </svg>
  )
}
