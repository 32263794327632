import React from 'react'
import { YMInitializer } from 'react-yandex-metrika'

const YandexMetrica = () => {
  return (
    <YMInitializer
      accounts={[93597994]}
      options={{
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        reachGoal: true,
        hit: true,
      }}
      version='2'
    />
  )
}

export default YandexMetrica
