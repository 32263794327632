import * as React from 'react'

export const HeartLogo = () => (
  <svg
    width='38'
    height='38'
    viewBox='0 0 38 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='1'
      y='1'
      width='36'
      height='36'
      rx='18'
      stroke='black'
      strokeWidth='2'
    />
    <path
      d='M27.9681 17.1739C27.7392 14.7681 25.7778 13 23.3587 13C21.7569 13 20.2858 13.7826 19.4358 15C18.6185 13.7536 17.2129 13 15.6437 13C13.2246 13 11.2631 14.7681 11.0343 17.1739C11.0016 17.2899 10.9362 17.8406 11.1651 18.7681C11.5247 20.1014 12.3419 21.2899 13.5188 22.2464L19.4358 27L25.4509 22.2464C26.6278 21.2899 27.445 20.1014 27.8046 18.7681C28.0662 17.8406 28.0008 17.2899 27.9681 17.1739Z'
      fill='black'
    />
  </svg>
)
