import 'moment/locale/ru'

import React, { useState } from 'react'
import ReactGA from 'react-ga'
import ym from 'react-yandex-metrika'

// import type { SelectionLocation } from '../../../store/selections/types'
// import { CarIcon } from '../../icons/CarIcon'
// import { WalkMan } from '../../icons/WalkMan'
import { TableRow } from './components/TableRow'
import styles from './table.module.scss'
import { TableTypes } from './Table.types'

// TODO: remove after approve the actual design
// const IS_SHOW_DELIVERY_TIME = false

export const Table = ({
  items,
  allSelections,
  isFirstSelection,
  position,
}: TableTypes) => {
  const [showMore, setShowMore] = useState(allSelections ? 10 : 3)

  const handleShowMoreItems = () => {
    ReactGA.ga('send', 'event', 'main', `projects ${position} list`, 'show')
    ym('reachGoal', 'projects list click', {
      'projects list click': {
        position: {
          'click type': 'show',
        },
      },
    })

    setShowMore(showMore + 7)
  }

  const handleCloseItems = () => {
    ReactGA.ga('send', 'event', 'main', `projects ${position} list`, 'hide')
    ym('reachGoal', 'projects list click', {
      'projects list click': {
        position: {
          'click type': 'hide',
        },
      },
    })
    setShowMore(3)
  }

  // const getDeliveryTimeContent = (location: SelectionLocation) => {
  //   if (!IS_SHOW_DELIVERY_TIME) {
  //     return
  //   }
  //
  //   return (
  //     <>
  //       {location.UF_METRO_TIME_ON_FOOT?.length < 10 && (
  //         <div className={styles.deliveryTime}>
  //           <WalkMan />
  //           <span>{location.UF_METRO_TIME_ON_FOOT} мин</span>
  //         </div>
  //       )}
  //       {location.UF_METRO_TIME_ON_TRANSPORT && (
  //         <div className={styles.deliveryTime}>
  //           <CarIcon />
  //           <span>{location.UF_METRO_TIME_ON_TRANSPORT} мин</span>
  //         </div>
  //       )}
  //     </>
  //   )
  // }

  return (
    <>
      <div className={styles.investmentTable}>
        {items?.slice(0, showMore).map((item, index) => {
          const { UF_URL, UF_BUILDING_NAME } = item

          return (
            <div className={styles.tableRow1} key={index}>
              <a
                href={UF_URL}
                target='_blank'
                onClick={() => {
                  ReactGA.ga(
                    'send',
                    'event',
                    'client link',
                    'PIK projects link',
                    UF_BUILDING_NAME
                  )
                  ReactGA.ga('rbcspec.send', 'event', 'client link', 'click')
                  ym('reachGoal', 'client link', {
                    'client link': {
                      'PIK projects link': {
                        link: UF_BUILDING_NAME,
                      },
                    },
                  })
                  // ym('reachGoal', 'client link', {
                  //   'client link': {
                  //     'PIK projects link': {
                  //       link: UF_URL,
                  //     },
                  //   },
                  // })
                }}
              >
                <TableRow data={item} isWithoutCity={isFirstSelection} />
              </a>
            </div>
          )
        })}
      </div>
      {showMore === 3 ? (
        <button className={styles.showAllBtn} onClick={handleShowMoreItems}>
          Показать весь список
        </button>
      ) : (
        !allSelections && (
          <button className={styles.showAllBtn} onClick={handleCloseItems}>
            Скрыть весь список
          </button>
        )
      )}
    </>
  )
}
