import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { motion } from 'framer-motion'
import React from 'react'
import ReactGA from 'react-ga'
import ym from 'react-yandex-metrika'

import bannerBg1 from '../../../assets/images/banners/1.jpg'
import bannerBg2 from '../../../assets/images/banners/2.jpg'
import { getAnimationFourParams } from '../../../helpers/animations'
import { ProgramsCarousel } from '../../UI/ProgramsCarousel/ProgramsCarousel'
import styles from './prigramsPIK.module.scss'
import { PageProps } from './ProgramsPIK.types'

export const ProgramsPik = ({ pageType }: PageProps) => {
  const firstLink =
    'https://www.pik.ru/search?currentBenefit=pyat-let-bez-procentov-2&utm_source=rbc&utm_medium=display&utm_content=mortgage'
  const secondLink =
    'https://www.pik.ru/search?currentBenefit=ipoteka-1-2&utm_source=rbc&utm_medium=display&utm_content=mortgage'
  const thirdLink =
    'https://www.pik.ru/search?currentBenefit=gotovoe-8-5&utm_source=rbc&utm_medium=display&utm_content=mortgage'
  const programsClick = (name: string) => {
    ReactGA.ga(
      'send',
      'event',
      'client link',
      `${pageType} PIK mortgage link`,
      name
    )
    ReactGA.ga('rbcspec.send', 'event', 'client link', 'click')
    ym('reachGoal', 'client link', {
      'client link': {
        'collection PIK mortgage link': {
          link: name,
        },
      },
    })
  }

  return (
    <motion.div
      {...getAnimationFourParams}
      className={styles.programsWrapper}
      id='#PIK'
    >
      <h3>Специальные ипотечные программы ПИК</h3>
      <div className={styles.programsCards}>
        <a
          className={styles.firstCard}
          href={firstLink}
          target={'_blank'}
          onClick={() => programsClick('Пять лет без процентов по ипотеке')}
        >
          <div
            className={styles.programsCardsBg}
            style={{ backgroundImage: `url(${bannerBg1})` }}
          ></div>
          <p>
            Пять лет
            <br />
            без процентов
            <br />
            по ипотеке
          </p>
        </a>
        <a
          className={styles.secondCard}
          href={secondLink}
          target={'_blank'}
          onClick={() => programsClick('Ипотека 1% до 15 июня')}
        >
          <div
            className={styles.programsCardsBg}
            style={{ backgroundColor: '#FFF' }}
          ></div>
          <p>
            Ипотека <span className={styles.programsCardsNumb}>1%</span>
            <span className={styles.programsCardsDate}>до 15 июня</span>
          </p>
          <div className={styles.programsCardsButton}>Подробнее</div>
        </a>
        <a
          className={styles.thirdCard}
          href={thirdLink}
          target={'_blank'}
          onClick={() => programsClick('Квартиры в готовых домах')}
        >
          <div
            className={styles.programsCardsBg}
            style={{ backgroundImage: `url(${bannerBg2})` }}
          ></div>
          <p>
            Квартиры
            <br />
            в&nbsp;готовых домах
          </p>
        </a>
      </div>
      <ProgramsCarousel pageType={pageType} />
    </motion.div>
  )
}
