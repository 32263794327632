import selection1Img from '../assets/images/selection1.jpg'
import selection2Img from '../assets/images/selection2.jpeg'
import selection3Img from '../assets/images/selection3.jpg'
import selection4Img from '../assets/images/selection4.jpg'
import selection5Img from '../assets/images/selection5.jpg'
import selection6Img from '../assets/images/selection6.jpg'
import selection7Img from '../assets/images/selection7.jpg'
import selection8Img from '../assets/images/selection8.jpg'
import selection9Img from '../assets/images/selection9.jpg'

export const selectionImages = {
  1: selection1Img,
  2: selection2Img,
  3: selection3Img,
  4: selection4Img,
  5: selection5Img,
  6: selection6Img,
  7: selection7Img,
  8: selection8Img,
  9: selection9Img,
}
