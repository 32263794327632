import styles from './profit.module.scss'
import { ProfitProps } from './Profit.types'

export const Profit = ({ title, text, color }: ProfitProps) => {
  return (
    <section className={styles.profit} style={{ backgroundColor: color }}>
      <div className={styles.col}>
        <h2 className={styles.title} style={{ whiteSpace: 'pre-wrap' }}>
          {title}
        </h2>
      </div>
      <div className={styles.col}>
        <p style={{ whiteSpace: 'pre-wrap' }} className={styles.text}>
          {text}
        </p>
      </div>
    </section>
  )
}
