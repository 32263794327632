import { Table } from '../../UI/Table/Table'
import styles from './tables.module.scss'
import { TablesProps } from './Tables.types'
export const Tables = ({
  title,
  table,
  isFirstSelection,
  position,
  isSettlement,
}: TablesProps) => {
  return (
    <section className={styles.tables}>
      <h3
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <Table
        items={table}
        allSelections
        isFirstSelection={isFirstSelection}
        position={position}
        isSettlement={isSettlement}
      />
    </section>
  )
}
