import React from 'react'
import ReactGA from 'react-ga'
import ym from 'react-yandex-metrika'

import { getColorByStationName, pluralize } from '../../helpers/common'
import { CarIcon } from '../icons/CarIcon'
import { FlashIcon } from '../icons/FlashIcon'
import { HoverIcon } from '../icons/HoverIcon'
import { WalkMan } from '../icons/WalkMan'
import styles from '../Main/newBuildings/newBuildings.module.scss'
import { ProjectCardTypes } from './ProjectCard.types'

export const ProjectCard = ({ data }: ProjectCardTypes) => {
  const {
    UF_BUILDING_NAME,
    LOCATION,
    CNT,
    UF_IMAGE,
    PRICE_PER_SQUARE,
    UF_BLOCKURL,
  } = data

  return (
    <a
      href={UF_BLOCKURL}
      target='_blank'
      onClick={() => {
        ReactGA.ga(
          'send',
          'event',
          'client link',
          'PIK new buildings link',
          UF_BUILDING_NAME
        )
        ReactGA.ga('rbcspec.send', 'event', 'client link', 'click')
        ym('reachGoal', 'client link', {
          'client link': {
            'PIK new buildings link': {
              link: UF_BUILDING_NAME,
            },
          },
        })
      }}
    >
      <div className={styles.projectCard}>
        <h4>{UF_BUILDING_NAME}</h4>
        {LOCATION?.UF_METRO_NAME?.length ? (
          <div className={styles.projectAddress}>
            <div
              style={{
                background: getColorByStationName(LOCATION?.UF_METRO_NAME),
              }}
            />
            <p>{LOCATION.UF_METRO_NAME}</p>
            {Number(LOCATION.UF_METRO_TIME_ON_FOOT) > 0 && (
              <>
                <WalkMan className={styles.walkManLogo} />
                <p>{LOCATION.UF_METRO_TIME_ON_FOOT} мин</p>
              </>
            )}

            {Number(LOCATION.UF_METRO_TIME_ON_TRANSPORT) > 0 &&
              Number(LOCATION.UF_METRO_TIME_ON_FOOT) <= 0 && (
                <>
                  <CarIcon
                    width='15px'
                    height='15px'
                    style={{ margin: '0 8px 0 20px' }}
                  />
                  <p>{LOCATION.UF_METRO_TIME_ON_TRANSPORT} мин</p>
                </>
              )}
          </div>
        ) : (
          <div style={{ height: '20px' }} />
        )}
        <div className={styles.projectImgBlock}>
          <div className={styles.projectHoverLinkIcon}>
            <HoverIcon />
          </div>
          {CNT <= 50 && (
            <div className={styles.flatsBlock}>
              <FlashIcon />
              <p>
                Осталось {pluralize(CNT, 'квартира', 'квартиры', 'квартир')}
              </p>
            </div>
          )}
          <img
            className={styles.projectImg}
            src={UF_IMAGE}
            alt={UF_BUILDING_NAME}
          />
        </div>
        <span>
          От {PRICE_PER_SQUARE.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽ за м²
        </span>
      </div>
    </a>
  )
}
