import akademikaPavlova from '../../../../assets/images/projectImages/AkademikaPavlova.jpeg'
import altufyevskoye54 from '../../../../assets/images/projectImages/Altufyevskoye53.jpeg'
import amurskiyPark from '../../../../assets/images/projectImages/AmurskiyPark.jpeg'
import barklaya6 from '../../../../assets/images/projectImages/Barklaya6.jpeg'
import belayaDachaPark from '../../../../assets/images/projectImages/BelayaDachaPark.jpeg'
import bolshayaAkadem85 from '../../../../assets/images/projectImages/BolshayaAkadem85.jpeg'
import bolshayaOchakovskaya2 from '../../../../assets/images/projectImages/BolshayaOchakovskaya2.jpeg'
import buninskiyeLuga from '../../../../assets/images/projectImages/BuninskiyeLuga.jpeg'
import businovskiyPark from '../../../../assets/images/projectImages/BusinovskiyPark.jpeg'
import butovoPark2 from '../../../../assets/images/projectImages/ButovoPark.jpeg'
import dmitrovskiyPark from '../../../../assets/images/projectImages/DmitrovskiyPark.jpeg'
import greenPark from '../../../../assets/images/projectImages/GreenPark.jpeg'
import hollandPark from '../../../../assets/images/projectImages/HollandPark.jpeg'
import ilmenskiy17 from '../../../../assets/images/projectImages/Ilmenskiy17.jpeg'
import ilyinskiyeLuga from '../../../../assets/images/projectImages/IlyinskiyeLuga.jpeg'
import izmaylovskiyLes from '../../../../assets/images/projectImages/IzmaylovskiyLes.jpeg'
import kavkazskiyBulvar51 from '../../../../assets/images/projectImages/KavkazskiyBulvar51.jpeg'
import kolskaya8 from '../../../../assets/images/projectImages/Kolskaya8.jpeg'
import krasnoKazarmennaya15 from '../../../../assets/images/projectImages/KrasnoKazarmennaya15.jpeg'
import kronshtadskiy9 from '../../../../assets/images/projectImages/Kronshtadskiy9.jpeg'
import kronshtadskiy14 from '../../../../assets/images/projectImages/Kronshtadskiy14.jpeg'
import kuzminskiyLes from '../../../../assets/images/projectImages/KuzminskiyLes.jpeg'
import losinoostrovskiyPark from '../../../../assets/images/projectImages/LosinoostrovskiyPark.jpeg'
import lyubercyPark from '../../../../assets/images/projectImages/LyubercyPark.jpeg'
import lyublinskiyPark from '../../../../assets/images/projectImages/LyublinskiyPark.jpeg'
import matveyevskiyPark from '../../../../assets/images/projectImages/MatveyevskiyPark.jpeg'
import mesherskiyLes from '../../../../assets/images/projectImages/MesherskiyLes.jpeg'
import michurinskiyPark from '../../../../assets/images/projectImages/MichurinskiyPark.jpeg'
import mihaylovskiyPark from '../../../../assets/images/projectImages/MihaylovskiyPark.jpeg'
import mitinskiyLes from '../../../../assets/images/projectImages/MitinskiyLes.jpeg'
import nikolskiyeLuga from '../../../../assets/images/projectImages/NikolskiyeLuga.jpeg'
import novohohlovskaya15 from '../../../../assets/images/projectImages/Novohohlovskaya15.jpeg'
import novoyeOchakovo from '../../../../assets/images/projectImages/NovoyeOchakovo.jpeg'
import odincovo1 from '../../../../assets/images/projectImages/Odincovo-1.jpeg'
import orangePark from '../../../../assets/images/projectImages/OrangePark.jpeg'
import otkritiyPark from '../../../../assets/images/projectImages/OtkritiyPark.jpeg'
import perovskoye2 from '../../../../assets/images/projectImages/Perovskoye2.jpeg'
import perviyDubrovskiy from '../../../../assets/images/projectImages/PerviyDubrovskiy.jpeg'
import polar from '../../../../assets/images/projectImages/Polar.jpeg'
import polyarnaya25 from '../../../../assets/images/projectImages/Polyarnaya25.jpeg'
import prostornaya7 from '../../../../assets/images/projectImages/Prostornaya7.jpeg'
import rimskogoKorsakova11 from '../../../../assets/images/projectImages/RimskogoKorsakova11.jpeg'
import rustaveli14 from '../../../../assets/images/projectImages/Rustaveli14.jpeg'
import salaryevoPark from '../../../../assets/images/projectImages/SalaryevoPark.jpeg'
import serednevskiyLes from '../../../../assets/images/projectImages/SerednevskiyLes.jpeg'
import signalniy16 from '../../../../assets/images/projectImages/Signalniy16.jpeg'
import sokolnicheskiyVal1 from '../../../../assets/images/projectImages/SokolnicheskiyVal1.jpeg'
import stolichniyePolyany from '../../../../assets/images/projectImages/StolichniyePolyany.jpeg'
import tomilinskiyBulvar from '../../../../assets/images/projectImages/TomilinskiyBulvar.jpeg'
import vanderPark from '../../../../assets/images/projectImages/VanderPark.jpeg'
import vangarden from '../../../../assets/images/projectImages/Vangarden.jpeg'
import volokolamskoye24 from '../../../../assets/images/projectImages/Volokolamskoye24.jpeg'
import volzhskiyPark from '../../../../assets/images/projectImages/VolzhskiyPark.jpeg'
import vostochnoyeButovo from '../../../../assets/images/projectImages/VostochnoyeButovo.jpeg'
import vtoroyNagatinskiy from '../../../../assets/images/projectImages/VtoroyNagatinskiy.jpeg'
import yasenevaya14 from '../../../../assets/images/projectImages/Yasenevaya14.jpeg'
import yunino from '../../../../assets/images/projectImages/Yunino.jpeg'
import yutanovo from '../../../../assets/images/projectImages/Yutanovo.jpeg'
import zapadniyPort from '../../../../assets/images/projectImages/ZapadniyPort.jpeg'
import zeleniyPark from '../../../../assets/images/projectImages/ZeleniyPark.jpeg'
import zhulebinoPark from '../../../../assets/images/projectImages/ZhulebinoPark.jpeg'
import { ProjectsImages } from '../NewBuildings.types'

export const moscowProjectsPictures: ProjectsImages[] = [
  {
    UF_BUILDING_NAME: 'Green park',
    UF_IMAGE: String(greenPark),
  },
  {
    UF_BUILDING_NAME: 'Holland park',
    UF_IMAGE: String(hollandPark),
  },
  {
    UF_BUILDING_NAME: 'Vangarden',
    UF_IMAGE: String(vangarden),
  },
  {
    UF_BUILDING_NAME: 'Академика Павлова',
    UF_IMAGE: String(akademikaPavlova),
  },
  {
    UF_BUILDING_NAME: 'Алтуфьевское 53',
    UF_IMAGE: String(altufyevskoye54),
  },
  {
    UF_BUILDING_NAME: 'Амурский парк',
    UF_IMAGE: String(amurskiyPark),
  },
  {
    UF_BUILDING_NAME: 'Барклая 6',
    UF_IMAGE: String(barklaya6),
  },
  {
    UF_BUILDING_NAME: 'Белая Дача парк',
    UF_IMAGE: String(belayaDachaPark),
  },
  {
    UF_BUILDING_NAME: 'Большая Академическая 85',
    UF_IMAGE: String(bolshayaAkadem85),
  },
  {
    UF_BUILDING_NAME: 'Большая Очаковская 2',
    UF_IMAGE: String(bolshayaOchakovskaya2),
  },
  {
    UF_BUILDING_NAME: 'Бунинские луга',
    UF_IMAGE: String(buninskiyeLuga),
  },
  {
    UF_BUILDING_NAME: 'Бусиновский парк',
    UF_IMAGE: String(businovskiyPark),
  },
  {
    UF_BUILDING_NAME: 'Бутово парк 2',
    UF_IMAGE: String(butovoPark2),
  },
  {
    UF_BUILDING_NAME: 'Волжский парк',
    UF_IMAGE: String(volzhskiyPark),
  },
  {
    UF_BUILDING_NAME: 'Волоколамское 24',
    UF_IMAGE: String(volokolamskoye24),
  },
  {
    UF_BUILDING_NAME: 'Восточное Бутово',
    UF_IMAGE: String(vostochnoyeButovo),
  },
  {
    UF_BUILDING_NAME: 'Второй Нагатинский',
    UF_IMAGE: String(vtoroyNagatinskiy),
  },
  {
    UF_BUILDING_NAME: 'Дмитровский парк',
    UF_IMAGE: String(dmitrovskiyPark),
  },
  {
    UF_BUILDING_NAME: 'Жулебино парк',
    UF_IMAGE: String(zhulebinoPark),
  },
  {
    UF_BUILDING_NAME: 'Западный порт',
    UF_IMAGE: String(zapadniyPort),
  },
  {
    UF_BUILDING_NAME: 'Зелёный парк',
    UF_IMAGE: String(zeleniyPark),
  },
  {
    UF_BUILDING_NAME: 'Измайловский лес',
    UF_IMAGE: String(izmaylovskiyLes),
  },
  {
    UF_BUILDING_NAME: 'Ильинские луга',
    UF_IMAGE: String(ilyinskiyeLuga),
  },
  {
    UF_BUILDING_NAME: 'Ильменский 17',
    UF_IMAGE: String(ilmenskiy17),
  },
  {
    UF_BUILDING_NAME: 'Кавказский бульвар 51',
    UF_IMAGE: String(kavkazskiyBulvar51),
  },
  {
    UF_BUILDING_NAME: 'Кольская 8',
    UF_IMAGE: String(kolskaya8),
  },
  {
    UF_BUILDING_NAME: 'Красноказарменная 15',
    UF_IMAGE: String(krasnoKazarmennaya15),
  },
  {
    UF_BUILDING_NAME: 'Кронштадтский 14',
    UF_IMAGE: String(kronshtadskiy14),
  },
  {
    UF_BUILDING_NAME: 'Кронштадтский 9',
    UF_IMAGE: String(kronshtadskiy9),
  },
  {
    UF_BUILDING_NAME: 'Кузьминский лес',
    UF_IMAGE: String(kuzminskiyLes),
  },
  {
    UF_BUILDING_NAME: 'Лосиноостровский парк',
    UF_IMAGE: String(losinoostrovskiyPark),
  },
  {
    UF_BUILDING_NAME: 'Люберцы парк',
    UF_IMAGE: String(lyubercyPark),
  },
  {
    UF_BUILDING_NAME: 'Люблинский парк',
    UF_IMAGE: String(lyublinskiyPark),
  },
  {
    UF_BUILDING_NAME: 'Матвеевский парк',
    UF_IMAGE: String(matveyevskiyPark),
  },
  {
    UF_BUILDING_NAME: 'Мещерский лес',
    UF_IMAGE: String(mesherskiyLes),
  },
  {
    UF_BUILDING_NAME: 'Митинский лес',
    UF_IMAGE: String(mitinskiyLes),
  },
  {
    UF_BUILDING_NAME: 'Михайловский парк',
    UF_IMAGE: String(mihaylovskiyPark),
  },
  {
    UF_BUILDING_NAME: 'Мичуринский парк',
    UF_IMAGE: String(michurinskiyPark),
  },
  {
    UF_BUILDING_NAME: 'Никольские луга',
    UF_IMAGE: String(nikolskiyeLuga),
  },
  {
    UF_BUILDING_NAME: 'Новое Очаково',
    UF_IMAGE: String(novoyeOchakovo),
  },
  {
    UF_BUILDING_NAME: 'Новохохловская 15',
    UF_IMAGE: String(novohohlovskaya15),
  },
  {
    UF_BUILDING_NAME: 'Одинцово-1',
    UF_IMAGE: String(odincovo1),
  },
  {
    UF_BUILDING_NAME: 'Оранж парк',
    UF_IMAGE: String(orangePark),
  },
  {
    UF_BUILDING_NAME: 'Открытый парк',
    UF_IMAGE: String(otkritiyPark),
  },
  {
    UF_BUILDING_NAME: 'Первый Дубровский',
    UF_IMAGE: String(perviyDubrovskiy),
  },
  {
    UF_BUILDING_NAME: 'Перовское 2',
    UF_IMAGE: String(perovskoye2),
  },
  {
    UF_BUILDING_NAME: 'Полар',
    UF_IMAGE: String(polar),
  },
  {
    UF_BUILDING_NAME: 'Полярная 25',
    UF_IMAGE: String(polyarnaya25),
  },
  {
    UF_BUILDING_NAME: 'Просторная 7',
    UF_IMAGE: String(prostornaya7),
  },
  {
    UF_BUILDING_NAME: 'Римского-Корсакова 11',
    UF_IMAGE: String(rimskogoKorsakova11),
  },
  {
    UF_BUILDING_NAME: 'Руставели 14',
    UF_IMAGE: String(rustaveli14),
  },
  {
    UF_BUILDING_NAME: 'Саларьево парк',
    UF_IMAGE: String(salaryevoPark),
  },
  {
    UF_BUILDING_NAME: 'Середневский лес',
    UF_IMAGE: String(serednevskiyLes),
  },
  {
    UF_BUILDING_NAME: 'Сигнальный 16',
    UF_IMAGE: String(signalniy16),
  },
  {
    UF_BUILDING_NAME: 'Сокольнический вал 1',
    UF_IMAGE: String(sokolnicheskiyVal1),
  },
  {
    UF_BUILDING_NAME: 'Столичные поляны',
    UF_IMAGE: String(stolichniyePolyany),
  },
  {
    UF_BUILDING_NAME: 'Томилинский бульвар',
    UF_IMAGE: String(tomilinskiyBulvar),
  },
  {
    UF_BUILDING_NAME: 'Юнино',
    UF_IMAGE: String(yunino),
  },
  {
    UF_BUILDING_NAME: 'Ютаново',
    UF_IMAGE: String(yutanovo),
  },
  {
    UF_BUILDING_NAME: 'Ясеневая 14',
    UF_IMAGE: String(yasenevaya14),
  },
  {
    UF_BUILDING_NAME: 'Преображенская площадь',
    UF_IMAGE: tomilinskiyBulvar,
  },
  {
    UF_BUILDING_NAME: 'Vander Park',
    UF_IMAGE: vanderPark,
  },
]
