import 'swiper/css'

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'

import articlesImg from '../../assets/images/articles-2.jpeg'
import image1 from '../../assets/images/pages/article-1/1.jpg'
import image2 from '../../assets/images/pages/article-1/2.jpg'
import image3 from '../../assets/images/pages/article-1/3.jpg'
import image4 from '../../assets/images/pages/article-1/4.jpg'
import image5 from '../../assets/images/pages/article-1/5.jpg'
import image6 from '../../assets/images/pages/article-1/6.jpg'
import image7 from '../../assets/images/pages/article-1/7.jpg'
import image8 from '../../assets/images/pages/article-1/8.jpg'
import image9 from '../../assets/images/pages/article-1/9.jpg'
import { Footer } from '../../components/Footer/Footer'
import { Header } from '../../components/Header/Header'
import { BackButton } from '../../components/UI/BackButton/BackButton'
import styles from '../../pages/ArticlesPage/articlePage.module.scss'
import styles2 from '../../scss/readAlso.module.scss'

export const ArticlePage1 = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Header />
      <main className={`${styles.articlePage} ${styles.article1}`}>
        <section className={styles.articleIntro}>
          <BackButton url='/' />
          <h1>
            Застройка оптом: что такое жилые кластеры и&nbsp;чем они хороши
          </h1>
          <div className={styles.articleLead}>
            В&nbsp;России изменение функции промышленных зон на&nbsp;жилую или
            общественную архитекторы называют трендом последних лет.
            В&nbsp;качестве успешных примеров можно назвать застройку территории
            Москва-Сити, бывшего завода &laquo;Серп и&nbsp;Молот&raquo;, ЗИЛ.
            <br />
            Рассказываем, что такое современные жилые кластеры, какие существуют
            стандарты и&nbsp;подходы в&nbsp;освоении крупных территорий.
          </div>
          <div className={styles.articleIntroImg}>
            <img src={image1} alt={''} />
          </div>
        </section>

        <section className={styles.articleContent}>
          <h2 className={styles.articleContentTitle}>
            Что даёт комплексный подход к&nbsp;застройке
          </h2>
          <div className={styles.articleContentItem}>
            <div className={styles.articleContentItemTextTop}>
              <p>
                Освоение крупных территорий бывших промзон позволяет создать
                фактически новый район, где не&nbsp;будет ни&nbsp;следа,
                ни&nbsp;намека на&nbsp;постсоветскую инфраструктуру,
                а&nbsp;новое пространство будет спланировано и&nbsp;построено
                по&nbsp;современным мировым стандартам.
              </p>
              <p>
                Преимущество таких пространств в&nbsp;том, что это крупные
                городские территории, которые сейчас находятся недалеко
                от&nbsp;центра, хотя ранее были окраинами города. Это дает
                возможность подойти к&nbsp;их&nbsp;развитию
                комплексно&nbsp;&mdash; от&nbsp;создания законченного
                архитектурного образа микрорайона до&nbsp;развитой
                социально-экономической инфраструктуры, говорит партнер бюро
                Wowhaus Олег Шапиро. Помимо постройки самих жилых домов, здесь,
                по&nbsp;его словам, могут быть учтены все аспекты современной
                жизни, что, естественно, будет преимуществом: и&nbsp;парки,
                и&nbsp;бульвары, и&nbsp;зеленые территории, и&nbsp;необходимое
                количество детских садов, школ и&nbsp;объектов медицинского
                обслуживания.
              </p>
            </div>
            <div className={styles.articleContentItemTextBottom}>
              <p>
                &laquo;При комплексном подходе к&nbsp;планированию
                и&nbsp;застройке территории, с&nbsp;одной стороны, есть
                возможность заранее учесть запрос современного жителя
                на&nbsp;привлекательную, благоприятную и&nbsp;безопасную среду
                для жизни и&nbsp;запрограммировать актуальные сценарии
                использования территории,&nbsp;&mdash; считает руководитель
                проектов бюро Atlas Анна Белинская.
                &mdash;&nbsp;А&nbsp;с&nbsp;другой, грамотно интегрировать
                территорию в&nbsp;городскую ткань. Такая интеграция
                в&nbsp;контекст города предполагает в&nbsp;том числе
                её&nbsp;включение в&nbsp;городскую экономику: расположение
                на&nbsp;территории бывших производственных зон дефицитных для
                прилегающих районов и&nbsp;города функций, а&nbsp;также создание
                связной системы транспортно-пешеходных перемещений&raquo;.
              </p>
            </div>
            <div
              className={`
              ${styles.articleContentItemIllustartion} ${styles.articleContentItemIllustartion3}
            `}
            >
              <div className={styles.articleTitle}>Комплексный подход</div>
              <div className={styles.articleText}>
                Такая интеграция в&nbsp;контекст города предполагает в&nbsp;этом
                числе её&nbsp;включение в&nbsp;городскую экономику
              </div>
            </div>
            <div
              className={`
              ${styles.articleContentItemPicture} ${styles.articleContentItemPictureMargin} ${styles.forDevices}
            `}
            >
              <figure>
                <img src={image2} alt={''} />
                <figcaption>Очаково-Матвеевский кластер</figcaption>
              </figure>
            </div>
          </div>
          <div
            className={`${styles.articleContentItemPicture} ${styles.articleContentItemMargin} ${styles.forDesktop}`}
          >
            <figure>
              <img src={image2} alt={''} />
              <figcaption>Очаково-Матвеевский кластер</figcaption>
            </figure>
          </div>
        </section>

        <section className={styles.articleContent}>
          <h2 className={styles.articleContentTitle}>
            Жилые кластеры: плюсы и&nbsp;минусы
          </h2>
          <div className={styles.articleContentItem}>
            <div className={styles.articleContentItemTextTop}>
              <p>
                В&nbsp;ПИК называют такие масштабные проекты жилыми кластерами.
                Одно из&nbsp;достоинств кластеров&nbsp;&mdash; новый жилой
                контекст &laquo;с&nbsp;иголочки&raquo;. Иными словами, зона
                комфорта распространяется не&nbsp;только на&nbsp;подъезд, двор,
                парковку и&nbsp;школу рядом&nbsp;&mdash; можно жить
                в&nbsp;заново выстроенном городском районе, поясняют
                в&nbsp;компании.
              </p>
            </div>
          </div>
        </section>

        <section className={styles.articleProduct}>
          <div className={styles.articleProductContainer}>
            <h2 className={styles.articleProductTitle}>
              Что такое жилой кластер
            </h2>
            <div className={styles.articleProductRow}>
              <div className={styles.articleProductCol}>
                <div className={styles.articleProductText}>
                  <span className={styles.articleProductName}>
                    Дарья Камышева,
                  </span>{' '}
                  вице-президент, директор департамента продукта:
                </div>
                <div className={styles.articleProductImg}>
                  <img src={image3} alt={''} />
                </div>
              </div>
              <div className={styles.articleProductCol}>
                <div className={styles.articleProductText}>
                  &laquo;ПИК стал одним из&nbsp;первых в&nbsp;отрасли, кто стал
                  применять комплексный подход к&nbsp;развитию масштабных
                  территорий и&nbsp;проектированию не&nbsp;просто жилых
                  кварталов, а&nbsp;цельной современной среды&nbsp;&mdash;
                  кластеров. И&nbsp;речь идет не&nbsp;столько
                  об&nbsp;образовательной, социальной инфраструктуре
                  и&nbsp;благоустроенных дворах, которые давно уже стали нормой
                  девелоперского продукта, сколько о&nbsp;формировании новых
                  общественных пространств и&nbsp;сервисов, закрывающих все
                  повседневные потребности жителей. Удалённая работа
                  и&nbsp;образование, встречи с&nbsp;друзьями, занятия спортом
                  и&nbsp;ментальным здоровьем, прогулки с&nbsp;домашними
                  питомцами&nbsp;&mdash; буквально все должно быть продумано
                  до&nbsp;мелочей.
                  <br />
                  Так, например, в&nbsp;Очаково-Матвеевском появится
                  многокилометровый пешеходный бульвар и&nbsp;мост&nbsp;&mdash;
                  зеленая артерия с&nbsp;примыкающими к&nbsp;ней бесшовными
                  прогулочными маршрутами, велосипедными дорожками, уютными
                  пекарнями и&nbsp;коворкингами, сенсорными садами
                  и&nbsp;плейхабами. Мы&nbsp;тщательно планируем
                  и&nbsp;сценируем жизнь в&nbsp;районе, насыщаем наши проекты
                  источниками впечатлений, чтобы создавалась атмосфера
                  добрососедства и&nbsp;каждый чувствовал сопричастность
                  месту&raquo;.
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.articleContent}>
          <div className={styles.articleContentItem}>
            <div className={styles.articleContentItemTextTop}>
              <p>
                Проект ПИК в&nbsp;Очаково-Матвеевском, где будет построено около
                1&nbsp;млн&nbsp;кв.&nbsp;м&nbsp;жилой недвижимости, школы,
                детсады и&nbsp;поликлиники, будут благоустроены дворы
                и&nbsp;созданы новый бульвар и&nbsp;парк, относится к&nbsp;жилым
                кластерам. На&nbsp;территории кластера жители смогут
                пользоваться разными объектами социальной и&nbsp;коммерческой
                инфраструктуры и&nbsp;прогулочными зонами. У&nbsp;покупателей
                будет большой выбор проектов с&nbsp;разными сроками
                строительства и&nbsp;стоимостью квартир, а&nbsp;также
                с&nbsp;отделкой и&nbsp;дополнительными опциями.
              </p>
            </div>
          </div>
        </section>

        <section
          className={`${styles.articleProduct} ${styles.articleProductViolet}`}
        >
          <div className={styles.articleProductContainer}>
            <h2 className={styles.articleProductTitle}>
              Проект ПИК <br />
              в&nbsp;Очаково-
              <br className={styles.forDesktop} />
              Матвеевском. <b className={styles.forDesktop} />
              Главное
            </h2>
            <div className={styles.articleProductRow}>
              <div className={styles.articleProductCol}>
                <div className={styles.articleProductImg}>
                  <img src={image4} alt={''} />
                </div>
              </div>
              <div className={styles.articleProductCol}>
                <div
                  className={`
                  ${styles.articleProductText} ${styles.articleProductTextTop}
                `}
                >
                  <b>Расположение:</b> Москва, район Очаково-Матвеевское
                  <br />
                  <b>Площадь участка:</b> 66,7&nbsp;га
                  <br />
                  <b>Площадь строительства:</b> 31,4&nbsp;га
                  <br />
                  <b>Число корпусов:</b> 96
                  <br />
                  <b>Число квартир:</b> 26&nbsp;505
                  <br />
                  <b>Этажность:</b> 9&ndash;33
                  <br />
                  <b>Площадь квартир:</b> 1&nbsp;238&nbsp;235&nbsp;кв.&nbsp;м
                  <br />
                  <b>Фасад:</b> навесные панели с&nbsp;рельефным кирпичом
                  и&nbsp;керамической плиткой, вентилируемые навесные
                  с&nbsp;бетонной плиткой
                  <br />
                  <b>Инфраструктура:</b> 6&nbsp;детских садов,
                  3&nbsp;общеобразовательных школы, 2&nbsp;начальных школы
                  <br />
                  <b>Благоустройство:</b> дворы-парки с&nbsp;зонами для отдыха,
                  занятий спортом и&nbsp;детских игр; пешеходный бульвар
                  от&nbsp;бюро Gillespies с&nbsp;сухим фонтаном, местами для
                  отдыха, зонами для воркаута и&nbsp;командных видов спорта,
                  велодорожками; площадка для выгула собак
                  <br />
                  <b>Парковка:</b> подземный паркинг, плоскостной паркинг
                  <br />
                  <b>Архитектор:</b> ПИК, бюро de&nbsp;Architekten Cie
                  (Vangarden)
                  <br />
                  <b>Девелопер:</b> ПИК
                  <br />
                  <b>Очереди строительства:</b> 23
                  <br />
                  <b>Сроки строительства:</b> III квартал 2030&nbsp;г.
                </div>
                <a className={`${styles.articleButton}`} href={'#'}>
                  Подробнее о&nbsp;проекте
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.articleSlider}>
          <Swiper
            slideToClickedSlide={true}
            breakpoints={{
              320: {
                width: 270,
                slidesPerView: 1,
                spaceBetween: 15,
              },
              768: {
                width: 670,
                slidesPerView: 1,
                spaceBetween: 15,
              },
              1024: {
                width: 829,
                slidesPerView: 1,
                spaceBetween: 35,
              },
              1376: {
                width: 829,
                slidesPerView: 1,
                spaceBetween: 50,
              },
              1600: {
                width: 829,
                slidesPerView: 1,
                spaceBetween: 50,
              },
            }}
          >
            <SwiperSlide>
              <div className={styles.articleSliderImg}>
                <figure>
                  <img src={image5} alt={''} />
                  <figcaption>Матвеевский парк</figcaption>
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.articleSliderImg}>
                <figure>
                  <img src={image6} alt={''} />
                  <figcaption>Большая Матвеевская</figcaption>
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.articleSliderImg}>
                <figure>
                  <img src={image5} alt={''} />
                  <figcaption>Матвеевский парк</figcaption>
                </figure>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.articleSliderImg}>
                <figure>
                  <img src={image6} alt={''} />
                  <figcaption>Большая Матвеевская</figcaption>
                </figure>
              </div>
            </SwiperSlide>
          </Swiper>
        </section>

        <section className={styles.articleContent}>
          <div className={styles.articleContentItem}>
            <div className={styles.articleContentItemTextTop}>
              <p>
                Строительство собственного района с&nbsp;нуля является одним
                из&nbsp;основных преимуществ кластерной застройки
                по&nbsp;сравнению с&nbsp;точечной, когда необходимо встраиваться
                в&nbsp;существующий район. Современный район позволяет
                обеспечить жителей всей необходимой инфраструктурой:
                коммерческими объектами, школами и&nbsp;детсадами, объектами
                разных категорий, сервисами под разные предпочтения
                и&nbsp;запросы. &laquo;Также за&nbsp;счёт объединения нескольких
                разных проектов в&nbsp;единую связанную территорию
                мы&nbsp;предоставляем потенциальному клиенту выбор
                не&nbsp;только объектов инфраструктуры и&nbsp;благоустройства,
                но&nbsp;и&nbsp;характеристик самой квартиры в&nbsp;интересующей
                локации&nbsp;&mdash; разных сроков строительства, стоимости,
                дополнительных фишек&raquo;,&nbsp;&mdash; поясняют в&nbsp;ПИК.
              </p>
              <p>
                Среди минусов крупных проектов эксперты называют сравнительно
                долгую реализацию в&nbsp;сравнении с&nbsp;обычной точечной
                застройкой. В&nbsp;случае покупки квартиры в&nbsp;жилом кластере
                при заселении соседние дома будут еще достраиваться. Это мало
                чем отличается от&nbsp;жизни в&nbsp;первых очередях при обычном
                строительстве, но&nbsp;этот факт тоже нужно учитывать.
              </p>
              <p>
                Еще одним минусом жилых кластеров можно назвать
                их&nbsp;постепенное заселение. Поначалу может казаться, что
                район слишком пустынный и&nbsp;неуютный. Но&nbsp;ожидание того
                стоит: в&nbsp;перспективе в&nbsp;жилых кластерах появляются
                всевозможные магазины, кафе и&nbsp;другие услуги.
              </p>
            </div>
          </div>
        </section>

        <section className={styles.articleTable}>
          <h2 className={styles.articleTableTitle}>
            Сравнение кластерных с&nbsp;точечными проектами
          </h2>
          <div className={styles.articleTableItem}>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Точечные проекты</th>
                  <th>Кластер</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>
                      Формат
                      <br />
                      строительства
                    </b>
                  </td>
                  <td>Интеграция проекта в&nbsp;сложившийся район</td>
                  <td>Строительство собственного современного района</td>
                </tr>
                <tr>
                  <td>
                    <b>Инфраструктура и&nbsp;благоустройство</b>
                  </td>
                  <td>
                    Объекты инфраструктуры и&nbsp;благоустройства, ограниченные
                    одним проектом
                  </td>
                  <td>
                    Разнообразие объектов инфраструктуры и&nbsp;благоустройства
                    за&nbsp;счет единства нескольких проектов
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Квартиры</b>
                  </td>
                  <td>
                    Сроки строительства, стоимость, варианты отделки,
                    определенные проектом
                  </td>
                  <td>
                    Возможность выбора с&nbsp;учетом разной стоимости
                    и&nbsp;сроков строительства в&nbsp;интересующей локации
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section className={styles.articleContent}>
          <div className={styles.articleContentItemPicture}>
            <figure>
              <img src={image7} alt={''} />
              <figcaption>Матвеевский парк</figcaption>
            </figure>
          </div>
        </section>

        <section className={styles.articleContent}>
          <h2 className={styles.articleContentTitle}>
            Что дают масштабные проекты жителям и&nbsp;городу
          </h2>
          <div className={styles.articleContentItem}>
            <div className={styles.articleContentItemTextTop}>
              <p>
                Для жителей польза от&nbsp;таких проектов в&nbsp;том, что они
                получают всю необходимую инфраструктуру рядом с&nbsp;домом.
                &laquo;У&nbsp;них нет потребности выезжать за&nbsp;пределы
                квартала, чтобы сходить в&nbsp;магазин или прогуляться
                с&nbsp;детьми. Это приводит, в&nbsp;свою очередь,
                к&nbsp;снижению нагрузки на&nbsp;транспортную систему города
                и&nbsp;уменьшению количества пробок. Учитывая, что для крупных
                городов проблема загруженности дорог стоит крайне остро,
                застройка промзон жилыми кварталами с&nbsp;комплексной
                инфраструктурой может стать успешным
                решением&raquo;,&nbsp;&mdash; говорит партнер архитектурного
                бюро UTRO Ольга Хохлова.
              </p>
            </div>
          </div>
        </section>

        <section
          className={`${styles.articleProduct} ${styles.articleProductViolet}`}
        >
          <div className={styles.articleProductContainer}>
            <h2 className={styles.articleProductTitle}>
              Все сервисы в&nbsp;доступности
            </h2>
            <div className={styles.articleProductRow}>
              <div className={styles.articleProductCol}>
                <div className={styles.articleProductText}>
                  <span className={styles.articleProductName}>
                    Олег Шапиро,
                  </span>{' '}
                  партнер бюро Wowhaus:
                </div>
                <div className={styles.articleProductImg}>
                  <img src={image8} alt={''} />
                </div>
              </div>
              <div className={styles.articleProductCo}>
                <div className={styles.articleProductText}>
                  &laquo;Поскольку бывшие промзоны&nbsp;&mdash; довольно крупные
                  территории, их&nbsp;можно рассматривать как самостоятельные
                  и&nbsp;даже несколько замкнутые на&nbsp;себе зоны, где
                  мы&nbsp;можем реализовать концепцию 15-минутного или просто
                  доступного города, когда жители получают все сервисы
                  в&nbsp;пешеходной или велосипедной доступности. Так как здесь
                  может проживать довольно большое количество людей,
                  то&nbsp;и&nbsp;количество запросов на&nbsp;городские сервисы
                  будет высокое.
                  <br />
                  <br />
                  Здесь может быть грамотно проработана городская среда:
                  пешеходная зона отделена от&nbsp;автомобильной, опасной
                  городской территории. Здесь могут безопасно находиться дети
                  и&nbsp;может быть образована своя безбарьерная среда. Такой
                  масштаб социального пространства даёт возможность тщательно
                  организовать его в&nbsp;соответствии со&nbsp;всеми
                  современными требованиями&raquo;.
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.articleProduct}>
          <div className={styles.articleProductContainer}>
            <h2 className={styles.articleProductTitle}>
              Комплексная застройка для города и&nbsp;будущих жильцов
            </h2>
            <div className={styles.articleProductRow}>
              <div className={styles.articleProductCol}>
                <div className={styles.articleProductText}>
                  <span className={styles.articleProductName}>
                    Анна Белинская,
                  </span>{' '}
                  руководитель проектов бюро Atlas:
                </div>
                <div className={styles.articleProductImg}>
                  <img src={image9} alt={''} />
                </div>
              </div>
              <div className={styles.articleProductCol}>
                <div
                  className={`
                  ${styles.articleProductText} ${styles.articleProductTextTop2}
                `}
                >
                  &laquo;Что это значит для города: появляется новый
                  многофункциональный и&nbsp;экономически активный район,
                  который отвечает на&nbsp;вызовы города и&nbsp;прилегающих
                  территорий и&nbsp;при позитивном сценарии становится импульсом
                  для развития соседних территорий. Кроме того, комплексная
                  застройка позволяет внедрить современные подходы, отвечающие
                  будущим рискам (например, экологичные, энергоэффективные или
                  умные решения), влияя таким образом на&nbsp;благополучие
                  города и&nbsp;горожан в&nbsp;среднесрочной и&nbsp;долгосрочной
                  перспективе.
                  <br />
                  Что это значит для будущих жителей: комплексный подход
                  позволяет сформировать жилую среду высокого качества, когда
                  приятно не&nbsp;только находиться в&nbsp;квартире,
                  но&nbsp;и&nbsp;работать, гулять и&nbsp;встречаться
                  с&nbsp;друзьями. В&nbsp;рамках комплексного освоения
                  закладываются решения, отвечающие основным запросам жителей
                  на&nbsp;наличие в&nbsp;шаговой доступности от&nbsp;дома
                  общественных пространств, озеленённых и&nbsp;рекреационных
                  территорий, а&nbsp;также социальных, коммерческих
                  и&nbsp;культурно-развлекательных функций&raquo;.
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.articleContent}>
          <a className={`${styles.articleButton}`} href={'#'}>
            Смотреть проекты ПИК в&nbsp;Очаково-Матвеевском
          </a>
        </section>

        <section className={styles2.readAlso}>
          <h2>Читайте также</h2>
          <div className={`${styles2.readAlsoList} ${styles2.readAlsoList2}`}>
            <div className={styles2.readAlsoListItem}>
              <img
                className={styles2.readAlsoPicture}
                src={articlesImg}
                alt={''}
              />
              <div className={styles2.readAlsoCaption}>
                <div className={styles2.readAlsoTitle}>
                  Жизнь в&nbsp;комфортной среде: стоит&nbsp;ли покупать квартиру
                  в&nbsp;готовом квартале
                </div>
                <a
                  className={`${styles2.readAlsoButton}`}
                  href={'#/article2/0'}
                >
                  Читать
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}
