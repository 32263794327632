import React from 'react'
import { IoIosArrowForward, IoMdClose } from 'react-icons/io'

import { getColorByStationName } from '../../../../helpers/common'
import { WalkMan } from '../../../icons/WalkMan'
import styles from '../../../Main/newBuildings/newBuildings.module.scss'
import { MapPopupCardTypes } from './MapPopupCard.types'

export const MapPopupCard = ({ data, closePopup }: MapPopupCardTypes) => {
  return (
    <div>
      <div
        className={`${styles.mapPopupBg} ${data.id ? '' : 'is-hidden'}`}
      ></div>
      <div className={`${styles.mapPopup} ${data.id ? '' : 'is-hidden'}`}>
        <button className={styles.mapPopupClose} onClick={closePopup}>
          <IoMdClose />
        </button>
        <div className={styles.mapPopupMedia}>
          <img src={data.image} alt={data.name} />
        </div>
        <div className={styles.mapPopupContent}>
          <div className={styles.mapPopupTitle}>{data.name}</div>
          {data.time ||
            (data.metroName && (
              <div className={styles.mapPopupDesc}>
                {data.metroName && (
                  <div className={styles.mapPopupDescItem}>
                    <div
                      className={styles.dot}
                      style={{
                        background: getColorByStationName(data.metroName),
                      }}
                    />
                    <span>{data.metroName}</span>
                  </div>
                )}
                {data.time && (
                  <div className={styles.mapPopupDescItem}>
                    <WalkMan />
                    <span>{data.time}</span>
                  </div>
                )}
              </div>
            ))}
          {data.price && (
            <div className={styles.mapPopupList}>
              {data.studio?.length ? (
                <div className={styles.mapPopupListItem}>
                  <p>Студия</p>
                  <p>
                    от {data.studio?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽
                  </p>
                </div>
              ) : (
                ''
              )}
              {data.rooms1?.length ? (
                <div className={styles.mapPopupListItem}>
                  <p>1 Комн.</p>
                  <p>
                    от {data.rooms1?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽
                  </p>
                </div>
              ) : (
                ''
              )}
              {data.rooms2?.length ? (
                <div className={styles.mapPopupListItem}>
                  <p>2 Комн.</p>
                  <p>
                    от {data.rooms2?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽
                  </p>
                </div>
              ) : (
                ''
              )}
              {data.rooms3?.length ? (
                <div className={styles.mapPopupListItem}>
                  <p>3+ Комн.</p>
                  <p>
                    от {data.rooms3?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
          )}
          <a href={data.url} className={styles.mapPopupLink} target='_blank'>
            <span>Подробнее о проекте</span>
            <IoIosArrowForward />
          </a>
        </div>
      </div>
    </div>
  )
}
