import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'

import {
  addWordToRenovation,
  handleSettlementDate,
  moscow,
  spb,
} from '../../../../consts/table'
import { getColorByStationName, pluralize } from '../../../../helpers/common'
import styles from '../table.module.scss'
import { TableRowTypes } from './TableRow.types'

export const TableRow = ({ data, isWithoutCity }: TableRowTypes) => {
  const {
    UF_BUILDING_NAME,
    LOCATION,
    UF_ROOMS,
    UF_AREA,
    UF_READY_QUARTER,
    UF_BUILD_YEAR,
    UF_PRICE,
    UF_SQUARE_PRICE,
    UF_RENOVATION,
    UF_STUDIO,
  } = data

  return (
    <div className={styles.tableRow}>
      <div className={styles.location}>
        <h4 className={styles.locationTitle}>{UF_BUILDING_NAME}</h4>
        {isWithoutCity ? (
          <div className={styles.subway}>
            {LOCATION.UF_LOCATION_NAME !== moscow &&
            LOCATION.UF_LOCATION_NAME !== spb ? (
              <p style={{ marginLeft: 0 }}>{LOCATION?.UF_LOCATION_NAME}</p>
            ) : (
              ''
            )}
            {LOCATION?.UF_METRO_NAME && (
              <>
                <div
                  style={{
                    background: getColorByStationName(LOCATION?.UF_METRO_NAME),
                  }}
                />
                <p>{LOCATION?.UF_METRO_NAME}</p>
              </>
            )}
          </div>
        ) : (
          <div className={styles.subway}>
            <p style={{ marginLeft: 0 }}>{LOCATION?.UF_LOCATION_NAME}</p>
          </div>
        )}
      </div>
      <div className={styles.secondCol}>
        {UF_STUDIO?.length ? (
          <p>Студия, {UF_AREA}м²</p>
        ) : (
          <p>
            {pluralize(Number(UF_ROOMS), 'комната', 'комнаты', 'комнат')},{' '}
            {UF_AREA}м²
          </p>
        )}
        {handleSettlementDate(UF_READY_QUARTER, UF_BUILD_YEAR) <=
        handleSettlementDate('31 марта', '2023') ? (
          <p>Готовая квартира</p>
        ) : (
          <p>
            Заселение до {UF_READY_QUARTER}&nbsp;
            {UF_BUILD_YEAR}
          </p>
        )}
      </div>
      <div className={styles.thirdCol}>
        <div className={styles.paymentBlock}>
          <p>Полная&nbsp;стоимость</p>
          <p>Цена за м²</p>
        </div>
        <div className={styles.amountPayable}>
          <p>{UF_PRICE.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽</p>
          <p>от {UF_SQUARE_PRICE.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽</p>
        </div>
      </div>
      <div className={styles.fourthCol}>
        <p>{addWordToRenovation(UF_RENOVATION)}</p>
        <IoIosArrowForward className={styles.arrowLink} />
      </div>
    </div>
  )
}
