import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useScrollTracker } from 'react-scroll-tracker'

import { AboutProject } from '../../components/AboutProject/AboutProject'
import { Tables } from '../../components/Collection/Tables/Tables'
import { Container } from '../../components/Container/Container'
import { Feedback } from '../../components/Main/Feedback/Feedback'
import { ProgramsPik } from '../../components/Main/ProgramsPIK/ProgramsPIK'
import { Profit } from '../../components/Profit/Profit'
import { BackButton } from '../../components/UI/BackButton/BackButton'
import { Layout } from '../../components/UI/Layout/Layout'
import { collectionsData } from '../../consts/collectionPageData'
import { gaScrollPercent } from '../../hooks/usePageViews'
import { fetchSelectionsRequest } from '../../store/selections/actions'
import { getSelections } from '../../store/selections/selectors'
import { DataProps } from './CollectionPage.types'

export const CollectionsPage = () => {
  const { id } = useParams()
  const [data, setData] = useState<DataProps>()
  const dispatch = useDispatch()
  // TODO оптимизировать
  const selections = useSelector(getSelections)

  const isFirstSelection = ['1', '3', '4', '5', '6', '8', '9'].includes(
    String(id)
  )

  useScrollTracker([25, 50, 75, 100], ({ scrollY }) => {
    gaScrollPercent(scrollY)
  })

  useEffect(() => {
    if (id) {
      setData(collectionsData.collections[Number(id) - 1])
    }
    dispatch(fetchSelectionsRequest(id as string))
  }, [])

  return (
    <Layout>
      <Container>
        <BackButton url='/' />
        {data?.id && (
          <>
            <AboutProject
              title={data.about.title}
              text={data.about.text}
              image={data.about.imagePath}
            />
            <Profit
              title={data.profit.title}
              text={data.profit.text}
              color={data.profit.color}
            />
            <Tables
              title={data.tables.title}
              table={selections?.items}
              isFirstSelection={isFirstSelection}
              position='top'
              isSettlement={id === '2'}
            />

            <ProgramsPik pageType='collection' />

            <Feedback />
          </>
        )}
      </Container>
    </Layout>
  )
}

// TODO зачем это?
// export const getStaticPaths = () => {
//   return {
//     paths: [], //indicates that no page needs be created at build time
//     fallback: 'blocking', //indicates the type of fallback
//   }
// }
