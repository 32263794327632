import { useMotionValueEvent, useScroll } from 'framer-motion'
import { throttle } from 'lodash'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { Link } from 'react-scroll'
import ym from 'react-yandex-metrika'

import { PhoneIcon } from '../icons/PhoneIcon'
import styles from './navMenu.module.scss'

export const NavMenu = () => {
  const [isShowMenu, setIsShowMenu] = useState(false)
  const [isScrollAtBottom, setIsScrollAtBottom] = useState(false)

  const { scrollY } = useScroll()

  useMotionValueEvent(scrollY, 'change', latest => {
    setIsShowMenu(latest > 50)
  })

  const handleScroll = throttle(() => {
    const headerHeight = document.querySelector('header')?.offsetHeight || 0
    const footerHeight = document.querySelector('footer')?.offsetHeight || 0
    const windowHeight = window.innerHeight
    const scrollY = window.scrollY
    const bodyHeight = document.body.offsetHeight - headerHeight - footerHeight
    const threshold = 0.9
    const isAtBottom = scrollY + windowHeight >= bodyHeight * threshold

    setIsScrollAtBottom(isAtBottom)
  }, 100)

  const googleAnalytics = (name: string) => {
    ReactGA.ga('send', 'event', 'main', 'filters click', name)
    ym('reachGoal', 'filters click', {
      'filters click': {
        main: {
          'filters name': name,
        },
      },
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [])

  return (
    <>
      {isShowMenu && (
        <div className={styles.navWrapperAbsolute}>
          <div
            className={`${styles.navWrapper} ${
              isScrollAtBottom && styles.navWrapper1
            }`}
          >
            <div className={styles.linksWrapper}>
              <Link
                to='#LifeWest'
                className={styles.link}
                onClick={() => googleAnalytics('Жизнь на западе Москвы')}
              >
                Жизнь на западе Москвы
              </Link>
              <Link
                to='#newBuildings'
                className={styles.link}
                onClick={() => googleAnalytics('Все новостройки')}
              >
                Все новостройки
              </Link>
              <Link
                to='#nearParks'
                className={styles.link}
                onClick={() => googleAnalytics('Квартиры у парков')}
              >
                Квартиры у парков
              </Link>
              <Link
                to='#selections'
                className={styles.link}
                onClick={() => googleAnalytics('Статьи и подборки')}
              >
                Статьи и подборки
              </Link>
              <Link
                to='#PIK'
                className={styles.link}
                onClick={() => googleAnalytics('Ипотека в ПИК')}
              >
                Ипотека в ПИК
              </Link>
            </div>
            <div className={styles.logoBlock}>
              <Link
                to='#feedback'
                onClick={() => googleAnalytics('Форма обратной связи')}
              >
                <PhoneIcon />
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
