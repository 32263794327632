import React from 'react'
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io'

import styles from './button.module.scss'
import { ButtonTypes } from './Button.types'

export const Button = ({
  content,
  width,
  onClick,
  arrowRight,
  arrowDown,
}: ButtonTypes) => {
  return (
    <button
      className={styles.button}
      style={{ width: width }}
      onClick={onClick}
    >
      {content}
      {arrowDown && <IoIosArrowDown style={{ marginLeft: '5px' }} />}
      {arrowRight && <IoIosArrowForward style={{ marginLeft: '5px' }} />}
    </button>
  )
}
