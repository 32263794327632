import React, { useState } from 'react'
import ReactGA from 'react-ga'
import ym from 'react-yandex-metrika'

import { activeStyles } from '../../consts/header'
import { Logo } from '../icons/Logo'
import { RedLogo } from '../icons/RedLogo'
import { BurgerMenu } from '../UI/BurgerMenu/BurgerMenu'
import styles from './header.module.scss'

export const Header = () => {
  const [active, setActive] = useState(false)

  const handleClickBurger = () => {
    setActive(!active)
    // TODO зачем тут этот код?
    // if (active) {
    //   document.documentElement.classList.add('background--red')
    // } else {
    //   document.documentElement.classList.remove('overflow--hidden')
    // }
  }

  const handleRbkClick = (rbk: boolean) => {
    if (rbk) {
      ReactGA.ga('send', 'event', 'nav', 'rbc logo')
      ym('reachGoal', 'rbc_logo_header_click', {
        rbc_logo_header_click: 'click',
      })
    } else {
      ReactGA.ga('send', 'event', 'client link', 'logo header')
      ReactGA.ga('rbcspec.send', 'event', 'client link', 'click')
      ym('reachGoal', 'client link', {
        'client link': {
          place: 'logo header',
        },
      })
    }
  }

  const pikLogoLink =
    'https://www.pik.ru/?utm_source=rbc&utm_medium=display&utm_content=logo&erid=4CQwVszH9pSaLkSpLGQ'

  return (
    <>
      <div className={styles.headerWrapper} style={active ? activeStyles : {}}>
        <div className={styles.rbkLogo}>
          <a
            href='https://rbc.ru/'
            target='_blank'
            onClick={() => handleRbkClick(true)}
          >
            <Logo />
          </a>
        </div>
        <div
          className={`${styles.burgerBtn} ${active ? styles.active : ''}`}
          onClick={handleClickBurger}
        >
          <span></span>
          <span></span>
        </div>
        <div className={styles.rightSideBlock}>
          <p className={styles.headerDescription}>совместный проект</p>
          <div className={styles.pikLogo}>
            <a
              href={pikLogoLink}
              target='_blank'
              onClick={() => handleRbkClick(false)}
            >
              <RedLogo />
            </a>
          </div>
        </div>
      </div>
      {active && <BurgerMenu handleClickBurger={handleClickBurger} />}
    </>
  )
}
