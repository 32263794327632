import 'swiper/css'

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import image2 from '../../assets/images/pages/article-2/article2-2.jpg'
import image3 from '../../assets/images/pages/article-2/article2-3.jpg'
import image4 from '../../assets/images/pages/article-2/article2-4.jpg'
import image5 from '../../assets/images/pages/article-2/article2-5.jpg'
import image6 from '../../assets/images/pages/article-2/article2-6.jpg'
import image7 from '../../assets/images/pages/article-2/article2-7.jpg'
import image1 from '../../assets/images/pages/article-2/article2Img-main.jpg'
import { ApartmentsTop } from '../../components/Announcements/ApartmentsTop/ApartmentsTop'
import { Articles } from '../../components/Article/Articles/Articles'
import { Feedback } from '../../components/Main/Feedback/Feedback'
import { BackButton } from '../../components/UI/BackButton/BackButton'
import { Layout } from '../../components/UI/Layout/Layout'
import styles from '../../pages/ArticlesPage/articlePage.module.scss'

export const ArticlePage2 = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <Layout>
      <section className={styles.articleIntro}>
        <BackButton url='/' />
        <h1>
          <b>Жизнь в&nbsp;комфортной среде:</b> <br />
          стоит&nbsp;ли покупать квартиру в&nbsp;готовом квартале
        </h1>
        <div className={styles.articleLead}>
          Покупка квартиры на&nbsp;ранней стадии строительства&nbsp;&mdash;
          отличное решение, если ищете новое жилье по&nbsp;минимально возможной
          цене. При этом на&nbsp;рынке можно найти варианты
          и&nbsp;в&nbsp;проектах на&nbsp;более высокой стадии строительства,
          когда до&nbsp;выдачи ключей осталось недолго, а&nbsp;рядом
          в&nbsp;том&nbsp;же ЖК&nbsp;есть уже заселенные дома. В&nbsp;таком
          случае, возможно, квартир в&nbsp;продаже будет меньше, потому что
          многое раскупают на&nbsp;ранних этапах, но&nbsp;есть другие
          преимущества. О&nbsp;них расскажем в&nbsp;материале.
        </div>
        <div className={styles.articleIntroImg}>
          <img src={image1} alt={''} />
        </div>
      </section>

      <section className={styles.articleContent}>
        <h2 className={styles.articleContentTitle}>
          В&nbsp;готовых кварталах более разнообразная социальная инфраструктура
        </h2>
        <div className={styles.articleContentItem}>
          <div className={styles.articleContentItemTextTop}>
            <p>
              Помимо дома и&nbsp;работы, социологи выделяют третье
              по&nbsp;важности место для человека&nbsp;&mdash; часть городского
              или районного пространства, где он&nbsp;проводит много времени,
              социализируется, развлекается. Отличительная особенность крупных
              жилых кварталов в&nbsp;том, что благодаря разнообразию
              инфраструктуры, социальных и&nbsp;коммерческих помещений жители
              могут найти свое &laquo;третье место&raquo; именно там.
            </p>
            <p>
              Ответственные девелоперы уже на&nbsp;ранних этапах строительства
              делают в&nbsp;своих кварталах полноценное благоустройство
              и&nbsp;стараются подарить жителям ощущение уюта
              и&nbsp;завершенности. В&nbsp;плане бытового комфорта это
              действительно так, а&nbsp;если где-то еще и&nbsp;ведется
              строительство, то&nbsp;оно изолировано и&nbsp;практически
              не&nbsp;ощущается. Поэтому в&nbsp;проекте, где помимо строящихся
              корпусов есть уже сданные и&nbsp;заселенные, можно и&nbsp;сразу
              увидеть окружение будущего жилья, и&nbsp;получить новую квартиру,
              и&nbsp;начать пользоваться современной инфраструктурой вокруг: чем
              больше корпусов, тем она разнообразнее.
            </p>
          </div>
        </div>

        <div className={styles.articleDescrImg}>
          <img src={image2} alt={''} />
        </div>

        <div className={styles.articleContentItem}>
          <div className={styles.articleContentItemTextTop}>
            <p>
              В&nbsp;течение пары лет после заселения первых жителей арендаторы
              занимают первые этажи, в&nbsp;районе появляются магазины
              и&nbsp;современные сервисы: кафе и&nbsp;рестораны, барбершопы
              и&nbsp;груминг, йога- и&nbsp;стретч-студии. Так формируется уже
              живое пространство, позволяющее получить многие функции,
              за&nbsp;которыми обычно или по&nbsp;привычке люди выезжают
              в&nbsp;другие районы или торговые центры. А&nbsp;еще за&nbsp;это
              время в&nbsp;районе успевает сформироваться соседское комьюнити.
            </p>
          </div>
          {/*<div*/}
          {/*  className={`*/}
          {/*    ${styles.articleContentItemIllustartion} ${styles.articleContentItemIllustartion4}*/}
          {/*  `}*/}
          {/*>*/}
          {/*  <div className={styles.articleTitle}>Стоимость жилья</div>*/}
          {/*  <div className={styles.articleText}>*/}
          {/*    разница в&nbsp;цене между готовым проектом, куда можно заселиться*/}
          {/*    заселиться в&nbsp;ближайшее время и&nbsp;получить сразу развитую*/}
          {/*    инфраструктуру района для жизни, и&nbsp;проектом на&nbsp;начальной*/}
          {/*    стадии, который будет сдан в&nbsp;эксплуатацию через*/}
          {/*    два-три-четыре года, сокращается в&nbsp;последние годы.*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </section>

      <section className={styles.articleContent}>
        <h2 className={styles.articleContentTitle}>
          Крупные проекты обеспечивают атмосферу &laquo;равнозначного
          соседства&raquo;
        </h2>
        <div className={styles.articleContentItem}>
          <div className={styles.articleContentItemTextTop}>
            <p>
              Застройка, состоящая из&nbsp;нескольких корпусов, создает ощущение
              консистентности окружения: нет чередования &laquo;серых
              панелек&raquo; и&nbsp;новых домов&nbsp;&mdash; все корпуса
              выполняются в&nbsp;едином современном стиле, социальная
              инфраструктура продумана и&nbsp;находится рядом, а&nbsp;обширная
              благоустроенная территория позволяет гулять на&nbsp;протяжении
              длительного времени. Последнее особенно важно для семей
              с&nbsp;детьми, одной из&nbsp;самых больших групп покупателей жилья
              в&nbsp;новостройках. В&nbsp;то&nbsp;же время точечная застройка,
              состоящая из&nbsp;одного-двух корпусов, может быть примером
              современного и&nbsp;качественного жилья, но&nbsp;из-за того, что
              окружение формировалось в&nbsp;разное время и&nbsp;с&nbsp;разными
              подходами, ощущения единства стиля и&nbsp;качества пространства
              не&nbsp;формируется.
            </p>
            <p>
              &laquo;Характерная особенность работы с&nbsp;комплексными
              проектами освоения территорий состоит в&nbsp;том, что
              стратегическое планирование в&nbsp;нем должно преобладать над
              оперативным. Подобные проекты важно развивать равномерно, заранее
              детально прорабатывать стратегию, распределение инвестиций,
              планирование работ&raquo;,&nbsp;&mdash; говорит руководитель
              архитектурного бюро T+T Architects Сергей Труханов.
            </p>
          </div>
        </div>

        <div className={styles.articleDescrImg}>
          <img src={image3} alt={''} />
        </div>
      </section>

      <section
        className={styles.articleProduct}
        style={{ background: '#E3D3F7' }}
      >
        <div className={styles.articleProductContainer}>
          <div className={styles.articleProductRowTop}>
            <h2 className={styles.articleProductTitle}>
              Как создается <br />
              цельная среда
            </h2>
            <div className={`${styles.articleProductImg} for-mobile`}>
              <img src={image4} alt={''} />
            </div>
            <div className={styles.articleProductText}>
              <span className={styles.articleProductName}>Дарья Камышева,</span>{' '}
              вице-президент, директор департамента продукта ПИК:
            </div>
          </div>
          <div className={styles.articleProductRow}>
            <div className={styles.articleProductCol}>
              <div className={`${styles.articleProductImg} for-desktop768`}>
                <img src={image4} alt={''} />
              </div>
            </div>
            <div className={styles.articleProductCol}>
              <div className={styles.articleProductText}>
                <p>
                  &mdash;&nbsp;Нам важно не&nbsp;просто продавать квадратные
                  метры, а&nbsp;организовывать цельную современную среду для
                  комфортной жизни, где человек будет чувствовать себя дома
                  с&nbsp;первой минуты после переезда. Цель ПИК&nbsp;&mdash;
                  создавать новые ориентиры благоустроенности во&nbsp;всем, что
                  ценят и&nbsp;к&nbsp;чему стремятся наши жители.
                  <br />
                  <br />
                </p>
                <p>
                  Работа над любым проектом ПИК начинается
                  с&nbsp;мастер-планирования&nbsp;&mdash; сценария
                  &laquo;организации жизни&raquo; района и&nbsp;стратегии его
                  развития. Задолго до&nbsp;старта строительства в&nbsp;проект
                  закладываются общественные пространства и&nbsp;сервисы,
                  закрывающие все повседневные потребности жителей:
                  от&nbsp;удаленной работы и&nbsp;заказа еды до&nbsp;занятий
                  спортом и&nbsp;выгула собак. Так, в&nbsp;жилых кварталах
                  появляются пешеходные бульвары и&nbsp;парки, спортивные
                  и&nbsp;детские площадки, коворкинги
                  и&nbsp;кофейни&nbsp;&mdash; всю инфраструктуру ПИК реализует
                  одновременно с&nbsp;жилыми корпусами
                  и&nbsp;в&nbsp;непосредственной близости от&nbsp;дома.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.articleContent}>
        <h2 className={styles.articleContentTitle}>
          В&nbsp;новых районах формируются разнообразные комьюнити
        </h2>
        <div className={styles.articleContentItem}>
          <div className={styles.articleContentItemTextTop}>
            <p>
              За&nbsp;последние десять лет проектирование и&nbsp;строительство
              жилья в&nbsp;России эволюционировало от&nbsp;простого возведения
              здания до&nbsp;создания девелоперского продукта, говорит партнер
              архитектурного бюро Syntaxis Александр Стариков. Сергей Труханов
              называет переход к&nbsp;созданию девелоперского продукта
              &laquo;идеальным сценарием эволюции рынка новостроек&raquo;.
              По&nbsp;его словам, такие сбалансированные проекты
              с&nbsp;целостной концепцией, где благоустройство, навигация,
              планировочные решения дополняют друг друга, появляются,
              но&nbsp;пока не&nbsp;стали основой предложения. В&nbsp;целом
              сейчас на&nbsp;рынке отмечается тенденция&nbsp;&mdash; девелоперы
              развивают район, а&nbsp;не&nbsp;просто свой земельный участок,
              отмечает руководитель Аналитического центра МУФ Виктория Мальцева.
              На&nbsp;развитие новых районов больше влияет и&nbsp;местное
              комьюнити. &laquo;Если оно формируется, то&nbsp;и&nbsp;районы
              становятся жилыми, а&nbsp;не&nbsp;спальниками, среда становится
              интересной и&nbsp;разнообразной&raquo;,&nbsp;&mdash; считает
              эксперт.
            </p>
            <p>
              Так, в&nbsp;проектах ПИК есть местные комьюнити: спортивные,
              беговые, йога-клубы, кофейни с&nbsp;регулярными тематическими
              встречами жителей.
            </p>
          </div>
        </div>

        <div className={styles.articleDescrImg}>
          <img src={image5} alt={''} />
        </div>
      </section>

      <section className={styles.articleContent}>
        <h2 className={styles.articleContentTitle}>
          Крупные проекты учитывают максимум потребностей жителей
        </h2>
        <div className={styles.articleContentItem}>
          <div className={styles.articleContentItemTextTop}>
            <p>
              Современный стандарт жилого девелопмента подразумевает создание
              высокоразвитой инфраструктуры, которая позволяет жителю получать
              все необходимые услуги и&nbsp;сервисы внутри своего района.
              &laquo;Сейчас крупные девелоперы с&nbsp;качественными проектами
              закладывают все, так сказать, район под ключ. Такие проекты
              в&nbsp;чистом поле, помимо условно обязательной инфраструктуры,
              подбирают такую, которая будет как минимум не&nbsp;хуже
              конкурентов: коммерческие первые этажи, качественное
              благоустройство (в&nbsp;расширенном формате, например плейхабы),
              иногда присутствует сложная инфраструктура&nbsp;&mdash; объекты
              здравоохранения, формирование полноценного почтового отделения,
              строительство&nbsp;ТЦ и&nbsp;БЦ&raquo;,&nbsp;&mdash; говорит
              Виктория Мальцева.
            </p>
            <p>
              Например, в&nbsp;проекте ПИК &laquo;Бунинские луга&raquo;
              построена и&nbsp;открыта одна школа на&nbsp;1100&nbsp;мест, три
              детских сада общей вместительностью 755&nbsp;мест. Также
              в&nbsp;2023 году планируется открытие сада на&nbsp;300&nbsp;мест.
              Сейчас уже есть детская поликлиника на&nbsp;140 посещений
              в&nbsp;смену и&nbsp;плейхаб&nbsp;&mdash; большое игровое
              пространство для разных возрастов. В&nbsp;проекте уже реализовано
              34&nbsp;корпуса общей площадью 607&nbsp;тыс.&nbsp;кв.&nbsp;м.
            </p>
          </div>
        </div>

        <div className={styles.articleDescrImg}>
          <img src={image6} alt={''} />
        </div>

        <div className={styles.articleContentItem}>
          <div className={styles.articleContentItemTextTop}>
            <p>
              Популяризация удаленных моделей труда и&nbsp;тренды урбанистики
              в&nbsp;целом ускорили распространение концепции &laquo;город
              в&nbsp;городе&raquo;, а&nbsp;идеи полицентризма получают все
              большее развитие. &laquo;Сейчас внутри современных жилых
              комплексов повысился спрос на&nbsp;качественные ресторанные
              концепции, коворкинги и&nbsp;районные общественные центры.
              Их&nbsp;создание помогает сформировать сеть самодостаточных
              многофункциональных мини-кластеров, снизить маятниковую миграцию,
              простимулировать муниципальные экономики и&nbsp;бизнес
              в&nbsp;потребительском секторе. Преимущество жилого строительства
              в&nbsp;формате комплексного освоения территорий перед точечной
              застройкой заключается в&nbsp;более всестороннем анализе
              и&nbsp;долгосрочном планировании, что позволяет обеспечить
              стабильный и&nbsp;качественный рост среды&raquo;,&nbsp;&mdash;
              считает архитектор Александр Стариков. При ответственном
              и&nbsp;этичном подходе, занимаясь последовательным освоением
              территорий, девелопер, по&nbsp;его словам, может создать более
              гуманное и&nbsp;комфортное пространство для жизни человека
              в&nbsp;рамках концепции 15-минутного города. Например,
              в&nbsp;проекте ПИК &laquo;Саларьево парк&raquo; уже построены
              и&nbsp;открыты школа, шесть детских садов, 42&nbsp;корпуса,
              плейхаб и&nbsp;надземный паркинг. Строятся два детских сада, три
              школы и&nbsp;пять корпусов.
            </p>
          </div>
        </div>

        <div className={styles.articleDescrImg}>
          <img src={image7} alt={''} />
        </div>
      </section>

      <section className={styles.articleContent}>
        <h2 className={styles.articleContentTitle}>
          Есть&nbsp;ли спрос на&nbsp;готовые новостройки
        </h2>
        <div className={styles.articleContentItem}>
          <div className={styles.articleContentItemTextTop}>
            <p>
              По&nbsp;итогам 2022 года спрос на&nbsp;готовые проекты составил
              32,4% от&nbsp;общего количества сделок на&nbsp;рынке первичного
              жилья Москвы (оценка риелторской компании Est-a-Tet). Этот
              показатель был на&nbsp;высоком уровне и&nbsp;в&nbsp;2021
              году&nbsp;&mdash; 31,4%. На&nbsp;начало 2023 года в&nbsp;сданных
              корпусах и&nbsp;проектах на&nbsp;стадии отделочных
              и&nbsp;внутренних работ было сосредоточено 30,3% от&nbsp;общего
              объема предложения на&nbsp;рынке первичного жилья Москвы. При этом
              доля предложения в&nbsp;уже построенных и&nbsp;введенных
              в&nbsp;эксплуатацию корпусах составляет 12,2%. Предложение
              в&nbsp;уже готовых домах на&nbsp;46,8% состоит из&nbsp;жилья
              бизнес-класса, на&nbsp;26,2%&nbsp;&mdash; комфорт-класса,
              17,4%&nbsp;&mdash; премиум-класса и&nbsp;9,6%&nbsp;&mdash;
              элитного сегмента. &laquo;Жилье в&nbsp;готовых корпусах пользуется
              спросом, так как по&nbsp;сравнению с&nbsp;еще строящимися&nbsp;ЖК
              здесь покупатель уже видит готовый результат и&nbsp;может сразу
              оценить качество строительства, отделки лотов и&nbsp;МОП, уровень
              благоустройства территории&raquo;,&nbsp;&mdash; говорит директор
              департамента проектного консалтинга Est-a-Tet Роман Родионцев.
            </p>
          </div>
        </div>
      </section>
      <section
        className={styles.articleProduct}
        style={{ background: '#e4f0d5' }}
      >
        <section className={styles.articleContent}>
          <ApartmentsTop withoutTable />
        </section>
      </section>
      <section className={styles.articleContent}>
        <Articles secondArticle />
      </section>
      <Feedback />
    </Layout>
  )
}
