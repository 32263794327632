import { all, call, put, takeLatest } from 'redux-saga/effects'

import request from '../../api/api'
import { fetchSelectionsFailure, fetchSelectionsSuccess } from './actions'
import { FETCH_SELECTIONS_REQUEST } from './actionTypes'

function* fetchSelectionsSagas({ payload, typeSelection }: any): any {
  try {
    const response = yield call(
      request.get,
      `/objects/selection/${typeSelection}`,
      payload
    )

    yield put(
      fetchSelectionsSuccess({
        selections: response.data,
      })
    )
  } catch (e: any) {
    yield put(
      fetchSelectionsFailure({
        error: e.message,
      })
    )
  }
}

function* selectionsSaga() {
  yield all([takeLatest(FETCH_SELECTIONS_REQUEST, fetchSelectionsSagas)])
}

export default selectionsSaga
