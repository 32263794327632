import React from 'react'

export const Logo = () => {
  return (
    <svg
      width='83'
      height='24'
      viewBox='0 0 83 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1_1326)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.850586 0.410156V21.2736L21.2439 0.410156H0.850586Z'
          fill='#141414'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.67004 23.1864H23.0584V2.32617L2.67004 23.1864Z'
          fill='#141414'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M38.8086 7.67151C38.8086 10.0306 37.4539 11.2775 34.8914 11.2775H32.1329V4.06846H34.8914C37.5639 4.06846 38.8086 5.17397 38.8086 7.54656V7.67151ZM34.894 0.445312H27.6816V23.1764H32.1355V14.6842H34.9857C40.1893 14.6842 43.1738 12.1295 43.1738 7.6745V7.54954C43.1738 3.03484 40.1556 0.445312 34.894 0.445312Z'
          fill='#141414'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M50.3832 12.781H53.6602C56.3369 12.781 57.6383 13.8679 57.6383 16.1034V16.2283C57.6383 18.4551 56.3512 19.5841 53.8124 19.5841H50.3832V12.781ZM54.0251 9.37396H50.3826V4.13264H60.0487V0.445312H45.9595V23.1764H53.9338C59.0215 23.1764 61.9393 20.6562 61.9393 16.2616V16.1362C61.9393 11.7755 59.1285 9.37396 54.0251 9.37396Z'
          fill='#141414'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M72.946 11.293L82.1465 0.410156H77.2575L69.1946 10.2014V0.440571H64.7408V23.1713H69.1946V13.1097L77.2864 23.1713H82.5923L72.946 11.293Z'
          fill='#141414'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_1326'>
          <rect width='83' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
