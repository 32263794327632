import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ym from 'react-yandex-metrika'

import { firstLoadRequest } from '../store/firstLoad/actions'
import { getFirstLoad } from '../store/firstLoad/selectors'

export const gaScrollPercent = (percent: number) => {
  ReactGA.ga('send', 'event', 'Scroll Depth', 'Percentage', `${percent}%`, {
    nonInteraction: false,
  })
  ReactGA.ga(
    'rbcspec.send',
    'event',
    'Scroll Depth',
    'Percentage',
    `${percent}%`,
    { nonInteraction: false }
  )

  ym('reachGoal', 'scroll depth', {
    'scroll depth': {
      percentage: `${percent}%`,
    },
  })
}

export function usePageViews() {
  const location = useLocation()
  const isFirstLoad = useSelector(getFirstLoad)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isFirstLoad) {
      ReactGA.ga(
        'set',
        'page',
        location.pathname + location.search + location.hash
      )
      ReactGA.ga(
        'send',
        'pageview',
        location.pathname + location.search + location.hash
      )
      ReactGA.ga(
        'rbcspec.set',
        'page',
        location.pathname + location.search + location.hash
      )
      ReactGA.ga(
        'rbcspec.send',
        'pageview',
        location.pathname + location.search + location.hash
      )
      ym('hit', location.pathname + location.search + location.hash)
    } else {
      dispatch(firstLoadRequest(false))
    }

    const timeout15Sec = setTimeout(() => {
      ReactGA.ga('rbcspec.send', 'event', 'page', 'spent_on_page_15_sec')
      ReactGA.ga('send', 'event', 'page', 'spent_on_page_15_sec')
      ym('reachGoal', 'spent_on_page_15_sec', {
        spent_on_page_15_sec: 'true',
      })
    }, 15000)

    return () => clearTimeout(timeout15Sec)
  }, [location])
}
