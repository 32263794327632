import { selectionImages } from '../components/collectionPage'

export const collectionsData = {
  collections: [
    {
      id: '1',
      about: {
        title: 'Квартиры в готовых проектах',
        text: 'Жилье в сданных проектах, где уже есть инфраструктура для жизни',
        imagePath: selectionImages['1'],
      },
      profit: {
        title: 'Почему \nэто выгодно',
        text: 'При покупке квартиры в готовом доме есть возможность не только быстро заселиться, но и получить сложившуюся и современную инфраструктуру. Новому жителю сразу будут доступны магазины, кафе и другие сервисы в пределах района.',
        color: '#e4f0d5',
      },
      tables: {
        title: 'Подборка квартир в готовых проектах',
      },
    },
    {
      id: '2',
      about: {
        title: 'Семейный топ: подборка больших квартир',
        text: 'Квартиры, которые подойдут для большой семьи и любителей простора',
        imagePath: selectionImages['2'],
      },
      profit: {
        title: 'Почему \nэто интересно',
        text:
          'Студии есть у любого застройщика, а вот большие и хорошо спланированные ' +
          'квартиры — не такое уж частое предложение на рынке новостроек.',
        color: '#E3D3F7',
      },
      tables: {
        title: 'Подборка квартир от 90 м²',
      },
    },
    {
      id: '3',
      about: {
        title: 'Без машины \nкуда угодно',
        text: 'Квартиры в проектах, расположенные в 15 минутах пешком от метро',
        imagePath: selectionImages['3'],
      },
      profit: {
        title: 'Почему \nэто выгодно',
        text: 'Квартира у метро - это возможность доехать до любого района без пробок и ожиданий. Это позволяет планировать и экономить время, а ещё такие квартиры всегда будут пользоваться спросом',
        color: '#e4f0d5',
      },
      tables: {
        title: 'Квартиры рядом с метро',
      },
    },
    {
      id: '4',
      about: {
        title: 'Квартиры в проектах с PlayHub и LocalPlay',
        text: 'Кварталы, где есть не просто детские площадки, а Playhub и LocalPlay - место для игры и отдыха',
        imagePath: selectionImages['4'],
      },
      profit: {
        title: 'Почему \nэто выгодно',
        text: 'Playhub — новая концепция игрового пространства и место для встреч и общения. Детям оно помогает социализироваться и творчески развиваться, а взрослым — формировать дружное сообщество жителей.',
        color: '#E3D3F7',
      },
      tables: {
        title: 'Место для игры и отдыха\n',
      },
    },
    {
      id: '5',
      about: {
        title: 'Квартиры с французскими балконами',
        text: 'Благодаря панорамному остеклению здесь всегда много света',
        imagePath: selectionImages['5'],
      },
      profit: {
        title: 'Почему \nэто выгодно',
        text: 'Панорамное остекление дает больше воздуха в пространстве и хороший уровень освещения. Это особенно важно, если вы любите светлые помещения или комната находится на теневой стороне.',
        color: '#e4f0d5',
      },
      tables: {
        title: 'Квартиры с окнами в пол',
      },
    },
    {
      id: '6',
      about: {
        title: 'Дома рядом с вузами',
        text: 'Проекты, из которых удобно добираться до учебы — дорога займет не больше 30 минут ',
        imagePath: selectionImages['6'],
      },
      profit: {
        title: 'Почему это интересно',
        text: 'В домах ПИК большой выбор планировок под разные сценарии. Например, молодой семье с будущим абитуриентом или как свое первое жилье для студента. А районы вблизи университетов традиционно лидируют в рейтингах недвижимости.',
        color: '#E3D3F7',
      },
      tables: {
        title: 'Проекты рядом с вузами',
      },
    },
    {
      id: '7',
      about: {
        title: 'Квартиры с высокими потолками',
        text: 'Высокие потолки делают квартиру визуально больше. А еще можно сделать второй ярус.',
        imagePath: selectionImages['7'],
      },
      profit: {
        title: 'Почему это интересно',
        text: 'Высокие потолки дают простор для дизайнерских решений и позволяют функционально использовать пространство. Например, сделать места для хранения вещей или второй уровень под спальню и кабинет.',
        color: '#e4f0d5',
      },
      tables: {
        title: 'Квартиры с высокими потолками',
      },
    },
    {
      id: '8',
      about: {
        title: 'Квартиры рядом с парками',
        text: 'Уникальная возможность жить в городе и не разрывать связь с природой',
        imagePath: selectionImages['8'],
      },
      profit: {
        title: 'Почему это интересно',
        text: 'Если живете рядом с парком, можно практически в любой момент прогуляться в тени деревьев или устроить семейный пикник. При этом близость с природой удачно сочетается с возможностью без труда поехать по делам в центр.',
        color: '#E3D3F7',
      },
      tables: {
        title: 'Квартиры рядом с парками',
      },
    },
    {
      id: '9',
      about: {
        title: 'Жизнь на западе Москвы',
        text: 'Районы на западе традиционно являются престижными, с хорошей экологией и парками',
        imagePath: selectionImages['9'],
      },
      profit: {
        title: 'Почему это интересно',
        text: 'Районы на западе столицы одни из самых экологически чистых: мало предприятий, много парков, а благодаря розе ветров — чистый воздух. Также здесь много исторических и интересных мест.',
        color: '#e4f0d5',
      },
      tables: {
        title: 'Жизнь на западе Москвы',
      },
    },
  ],
}
