import { all, call, put, takeLatest } from 'redux-saga/effects'

import request from '../../api/api'
import { fetchObjectsFailure, fetchObjectsSuccess } from './actions'
import { FETCH_OBJECTS_REQUEST } from './actionTypes'

function* fetchObjectsSagas({
  payload,
  city,
  rooms,
  priceFrom,
  priceTo,
}: any): any {
  try {
    const response = yield call(
      request.get,
      `/objects/${city}?rooms=${rooms}&price-from=${priceFrom}&price-to=${priceTo}`,
      payload
    )

    yield put(
      fetchObjectsSuccess({
        objects: response.data,
      })
    )
  } catch (e: any) {
    yield put(
      fetchObjectsFailure({
        error: e.message,
      })
    )
  }
}

function* objectsSaga() {
  yield all([takeLatest(FETCH_OBJECTS_REQUEST, fetchObjectsSagas)])
}

export default objectsSaga
