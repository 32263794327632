import { FIRSTLOAD_REQUEST } from './actionTypes'
import { FirstLoadAction, FirstLoadState } from './types'

const initialState: FirstLoadState = {
  pending: false,
  firstLoad: true,
  error: null,
}
export const firstLoadReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: FirstLoadAction
) => {
  switch (action.type) {
    case FIRSTLOAD_REQUEST:
      return {
        ...state,
        pending: true,
        firstLoad: action.firstLoad,
      }
    default:
      return {
        ...state,
      }
  }
}
export default firstLoadReducer
