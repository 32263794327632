import 'swiper/css'

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import mainImg from '../../assets/images/pages/article-3/article3-main.jpg'
import image2 from '../../assets/images/pages/article-3/articleImg-2.jpg'
import image3_1 from '../../assets/images/pages/article-3/articleImg-3-1.jpg'
import image3_2 from '../../assets/images/pages/article-3/articleImg-3-2.jpg'
import image3_3 from '../../assets/images/pages/article-3/articleImg-3-3.jpg'
import image4_1 from '../../assets/images/pages/article-3/articleImg-4-1.jpg'
import image4_2 from '../../assets/images/pages/article-3/articleImg-4-2.jpg'
import image4_3 from '../../assets/images/pages/article-3/articleImg-4-3.jpg'
import image5_1 from '../../assets/images/pages/article-3/articleImg-5-1.jpg'
import image5_2 from '../../assets/images/pages/article-3/articleImg-5-2.jpg'
import image5_3 from '../../assets/images/pages/article-3/articleImg-5-3.jpg'
import image6_1 from '../../assets/images/pages/article-3/articleImg-6-1.jpg'
import image6_2 from '../../assets/images/pages/article-3/articleImg-6-2.jpg'
import image7_1 from '../../assets/images/pages/article-3/articleImg-7-1.jpg'
import image7_2 from '../../assets/images/pages/article-3/articleImg-7-2.jpg'
import image7_3 from '../../assets/images/pages/article-3/articleImg-7-3.jpg'
import image8 from '../../assets/images/pages/article-3/articleImg-8.jpg'
import { Playhub } from '../../components/Announcements/Playhub/Playhub'
import { Articles } from '../../components/Article/Articles/Articles'
import { Feedback } from '../../components/Main/Feedback/Feedback'
import { BackButton } from '../../components/UI/BackButton/BackButton'
import { Layout } from '../../components/UI/Layout/Layout'
import { ImageSwiper } from '../../components/UI/Swiper/Swiper'
import styles from '../../pages/ArticlesPage/articlePage.module.scss'

export const ArticlePage3 = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <Layout>
      <section className={styles.articleIntro}>
        <BackButton url='/' />
        <h1>
          <b>Город для детей:</b> <br />
          детство и&nbsp;родительство в&nbsp;современных жилых кварталах
        </h1>
        <div className={styles.articleLead}>
          <p>
            Жилые кварталы меняются вслед за&nbsp;потребностями покупателей
            и&nbsp;современными стандартами городской среды. Для родителей
            и&nbsp;детей в&nbsp;плане развлечений, отдыха и&nbsp;развития
            на&nbsp;первый план выходит безопасность, повышаются стандарты
            качества игровых пространств, школ и&nbsp;детских садов
            и&nbsp;в&nbsp;целом инфраструктуры района.
          </p>
          <p>
            Вместе с&nbsp;архитекторами и&nbsp;урбанистами, психофизиологами
            и&nbsp;застройщиками разбираемся, как различаются требования
            к&nbsp;инфраструктуре в&nbsp;зависимости от&nbsp;возраста
            и&nbsp;какие особенности городской среды могут позитивно влиять
            на&nbsp;развитие детей.
          </p>
        </div>
        <div className={styles.articleIntroImg}>
          <img style={{ objectPosition: '50% 90%' }} src={mainImg} alt={''} />
        </div>
      </section>

      <section className={styles.articleContent}>
        <h2 className={styles.articleContentTitle}>
          Что нужно детям в&nbsp;современных жилых кварталах
        </h2>
        <div className={styles.articleContentItem}>
          <div className={styles.articleContentItemTextTop}>
            <p>
              Лучшее, что среда может дать детям всех возрастов,&nbsp;&mdash;
              это разнообразие, отмечают эксперты. &laquo;Именно этот фактор
              влияет на&nbsp;развитие ребенка, потому что мотивирует
              к&nbsp;исследованию. Чем разнообразнее окружающая обстановка, тем
              больше возможностей для исследования, тем интенсивнее происходит
              развитие&raquo;,&nbsp;&mdash; отмечает&nbsp;и.&nbsp;о. заместителя
              декана по&nbsp;научной работе факультета городского
              и&nbsp;регионального развития НИУ ВШЭ Диана Кодзокова.
            </p>
            <p>
              Исследования НИУ ВШЭ подтверждают, что развитие детей
              и&nbsp;подростков&nbsp;&mdash; это функция среды. &laquo;Чтобы это
              в&nbsp;принципе было возможным, среда должна отвечать базовым
              потребностям&nbsp;&mdash; и&nbsp;тут мы&nbsp;говорим
              о&nbsp;доступности, безопасности, причем как объективной
              (комфортные, зонированные по&nbsp;функциям и&nbsp;возрастам
              детские площадки, освещение, разделение между проезжей частью
              и&nbsp;зонами отдыха и&nbsp;игр и&nbsp;пр.), так
              и&nbsp;субъективной. Эти характеристики и&nbsp;нормативы для них
              учтены в&nbsp;градостроительных политиках&raquo;,&nbsp;&mdash;
              говорит Диана Кодзокова.
            </p>
            <p>
              &laquo;При создании современных жилых районов необходимо еще
              на&nbsp;этапе проектирования продумать все до&nbsp;мелочей:
              удобные, безопасные пешеходные связи со&nbsp;школами, детскими
              садами, кафе, магазинами, остановками общественного транспорта
              и&nbsp;парковками. Не&nbsp;забыть про маршруты
              и&nbsp;инфраструктуру для велосипедов, самокатов и&nbsp;других
              средств персональной мобильности. Важно создать разнообразие
              общественных пространств и&nbsp;функций, закрывающих максимум
              повседневных потребностей жителей всех возрастов: организовать
              возможности для отдыха и&nbsp;развлечения, работы и&nbsp;занятий
              спортом. То&nbsp;есть тщательно спланировать и&nbsp;сценировать
              жизнь человека в&nbsp;районе, грамотно зонировать территорию
              проекта, цельно и&nbsp;органично увязать все между
              собой&raquo;,&nbsp;&mdash; рассказывает Дарья Камышева, директор
              департамента продукта ПИК.
            </p>
          </div>
        </div>

        <div className={styles.articleDescrImg}>
          <img src={image2} alt={''} />
        </div>

        <div className={styles.articleContentItem}>
          <div className={styles.articleContentItemTextTop}>
            <p>
              Проектируя жилые районы, ПИК создает цельное, современное
              и&nbsp;уютное пространство, а&nbsp;не&nbsp;просто выполняет
              минимальные требования по&nbsp;благоустройству.
              В&nbsp;среднем&nbsp;40% площади жилых районов ПИК&nbsp;&mdash; это
              благоустроенные территории для прогулок и&nbsp;отдыха жителей,
              в&nbsp;то&nbsp;время как необходимый по&nbsp;строительным
              стандартам минимум в&nbsp;два раза меньше.
            </p>
            <p>
              При проектировании нужно учитывать множество нюансов. Нельзя
              предложить универсальное для всех детей решение&nbsp;&mdash; они
              разные, с&nbsp;разными потребностями и&nbsp;интересами.
              &laquo;Помимо возраста, они сильно различаются тем, как познают
              пространство вокруг, какие практики в&nbsp;нем реализуют.
              И&nbsp;если город и&nbsp;проектировщики понимают, как работать для
              детей младшего возраста, то&nbsp;со&nbsp;старшим детским возрастом
              все сложнее&raquo;,&nbsp;&mdash; говорит Диана Кодзокова
              из&nbsp;НИУ ВШЭ. По&nbsp;ее&nbsp;мнению, важно не&nbsp;забывать
              о&nbsp;потребностях подростков, которые переходят от&nbsp;игры как
              основной формы коммуникации к&nbsp;общению,
              и&nbsp;им&nbsp;требуются пространства, отвечающие этой
              потребности. О&nbsp;таких пространствах ведется разговор
              в&nbsp;профессиональном сообществе, но&nbsp;в&nbsp;документах это
              практически не&nbsp;отражено, отмечает урбанист.
            </p>
          </div>
        </div>

        <ImageSwiper
          firstImg={image3_1}
          secondImg={image3_2}
          thirdImg={image3_3}
        />
      </section>

      <section className={styles.articleContent}>
        <h2 className={styles.articleContentTitle}>
          Современный подход к&nbsp;школам и&nbsp;детским садам
        </h2>
        <div className={styles.articleContentItem}>
          <div className={styles.articleContentItemTextTop}>
            <p>
              Сейчас детский сад и&nbsp;школа&nbsp;&mdash; это не&nbsp;просто
              здания. &laquo;В&nbsp;современной концепции образование
              не&nbsp;заканчивается нигде&nbsp;&mdash;
              ни&nbsp;в&nbsp;пространстве, ни&nbsp;во&nbsp;времени.
              Предполагается, что человек учится всю жизнь и&nbsp;добирает
              знания там, где появляется&raquo;,&nbsp;&mdash; отмечает
              сооснователь архбюро Wowhaus Олег Шапиро. С&nbsp;этим мнением
              согласен генеральный директор архбюро ИМАГО Сергей Остроух:
              &laquo;Мы&nbsp;замечаем, что обучение все больше перемещается
              за&nbsp;пределы стен школы: оно начинается в&nbsp;семье
              и&nbsp;в&nbsp;общении с&nbsp;друзьями, расширяется по&nbsp;мере
              взросления до&nbsp;уровня района, города, страны, а&nbsp;затем
              и&nbsp;всего мира. Если посмотреть на&nbsp;образование ребенка
              в&nbsp;более широком контексте, то&nbsp;оно происходит везде. Все,
              с&nbsp;чем ребенок соприкасается, где он&nbsp;бывает, что видит,
              что производит на&nbsp;него впечатление,&nbsp;&mdash; все это
              влияет на&nbsp;его развитие. Задача современной школы&nbsp;&mdash;
              быть центром, где ребенку помогут выстраивать цельный пазл
              восприятия мира вокруг&raquo;.
            </p>
            <p>
              Девелоперы нечасто создают социальную инфраструктуру новых
              кварталов: собственные школы&nbsp;и (или) детские сады
              предусмотрены в&nbsp;35,8% проектов на&nbsp;московском рынке
              первичного жилья, подсчитали аналитики &laquo;Метриум&raquo;.
              В&nbsp;массовом сегменте этот показатель выше&nbsp;&mdash; около
              53,2%, в&nbsp;категории элитного жилья лишь 6,25% новостроек сдают
              с&nbsp;образовательной инфраструктурой.{' '}
              <a
                href={
                  'https://www.metrium.ru/news/detail/sobstvennye-shkoly-i-detskie-sady-predusmotreny-vsego-v-treti-stolichnykh-novostroek/'
                }
                target={'_blank'}
              >
                По&nbsp;данным &laquo;Метриум&raquo;
              </a>
              , ПИК является самым социально ориентированным девелопером.
              В&nbsp;проектах компании школы и&nbsp;сады спроектированы так,
              чтобы стать для детей и&nbsp;местом учебы, и&nbsp;пространством,
              где хочется проводить свободное время, играть, общаться.
              Во&nbsp;многих школах создаются IT-лаборатории, кружки
              робототехники и&nbsp;условия для других современных направлений
              образования. К&nbsp;примеру, в&nbsp;жилом квартале
              &laquo;Матвеевский парк&raquo;, который ПИК строит на&nbsp;западе
              Москве, появится школа, партнером которой станет Центр
              педагогического мастерства. Он&nbsp;специализируется
              на&nbsp;подготовке к&nbsp;олимпиадам и&nbsp;входит в&nbsp;топ-30
              лучших частных школ Москвы по&nbsp;версии Forbes Education. Что
              касается инфраструктуры за&nbsp;пределами школьных стен, вокруг
              всех школьных зданий благоустроена территория для прогулок
              и&nbsp;игр, есть футбольные поля, а&nbsp;также площадки для
              баскетбола и&nbsp;волейбола.
            </p>
          </div>
        </div>

        <ImageSwiper
          firstImg={image4_1}
          secondImg={image4_2}
          thirdImg={image4_3}
        />

        <div className={styles.articleContentItem}>
          <div className={styles.articleContentItemTextTop}>
            <p>
              &laquo;Сейчас довольно много концепций образования&nbsp;&mdash;
              и&nbsp;дошкольного, и&nbsp;школьного&nbsp;&mdash; связано
              с&nbsp;тем, что мы&nbsp;обращаем внимание не&nbsp;только
              на&nbsp;то, что мы&nbsp;преподаем, каким образом и&nbsp;какими
              методиками, но&nbsp;и&nbsp;в&nbsp;какой среде и&nbsp;в&nbsp;каком
              пространстве. Одна из&nbsp;методик, реджио, в&nbsp;своем манифесте
              называет пространство &bdquo;третьим
              преподавателем/воспитателем&ldquo;. Первый
              воспитатель&nbsp;&mdash; учитель, который ведет занятия, второй
              перманентный воспитатель&nbsp;&mdash; это родители,
              а&nbsp;третий&nbsp;&mdash; пространство, в&nbsp;котором ведется
              образование&raquo;,&nbsp;&mdash; отмечает Олег Шапиро.
            </p>
            <p>
              Застройщики, которые придерживаются современных концепций создания
              образовательной среды, применяют такие практики. Например, детские
              сады в&nbsp;проектах ПИК оформляются приятными на&nbsp;ощупь
              материалами, варьируются формы, объемы, цвета и&nbsp;фактуры.
              В&nbsp;компании отмечают, что такое разнообразие побуждает детей
              к&nbsp;познанию, стимулирует их&nbsp;творческие способности.
              В&nbsp;интерьерах детских садов застройщик использует элементы,
              которые помогают детям понять важность личного пространства.
              Например, ребенок может сам выбрать шкафчик с&nbsp;любимым
              изображением. Окна игровых и&nbsp;спален выходят
              на&nbsp;территорию сада, а&nbsp;не&nbsp;на&nbsp;улицу, чтобы
              снизить уровень шума. За&nbsp;счет больших окон застройщик
              максимально освещает помещения, где дети проводят большую часть
              дня.
            </p>
            <p>
              При этом школы и&nbsp;сады должны быть максимально органично
              вписаны в&nbsp;городскую среду, чтобы детей можно было выводить
              в&nbsp;парки через безопасные маршруты и&nbsp;чтобы сами объекты
              образования были максимально открыты городу, добавляет Сергей
              Остроух.
            </p>
          </div>
        </div>
      </section>

      <section
        className={styles.articleProduct}
        style={{ background: '#E3D3F7' }}
      >
        <div className={styles.articleProductContainer}>
          <div className={styles.articleProductRowTop}>
            <h2 className={styles.articleProductTitle}>
              Правильные дворы для детей с&nbsp;позиции психофизиологии
            </h2>
            <div className={`${styles.articleProductImg} for-mobile`}>
              <img src={image8} alt={''} />
            </div>
            <div className={styles.articleProductText}>
              <span className={styles.articleProductName}>
                Виктория Ефимова,
              </span>{' '}
              психофизиолог, доктор психологических наук, доцент кафедры
              возрастной психологии и&nbsp;педагогики семьи РГПУ им.
              А.&nbsp;И.&nbsp;Герцена:
            </div>
          </div>
          <div className={styles.articleProductRow}>
            <div className={styles.articleProductCol}>
              <div className={`${styles.articleProductImg} for-desktop768`}>
                <img src={image8} alt={''} />
              </div>
            </div>
            <div className={styles.articleProductCol}>
              <div className={styles.articleProductText}>
                <p>
                  &mdash;&nbsp;Городская среда должна быть организована таким
                  образом, чтобы у&nbsp;детей была возможность для общения
                  и&nbsp;подвижных игр. Если мы&nbsp;говорим о&nbsp;больших
                  городах, то&nbsp;на&nbsp;территории&nbsp;ЖК обязательно должны
                  быть огороженные охраняемые пространства без машин, где дети
                  могут безопасно бегать, играть и&nbsp;общаться. Это важно для
                  их&nbsp;социального, физического, эмоционального
                  и&nbsp;умственного развития.
                  <br />
                  <br />
                </p>
                <p>
                  Для дошкольников нужно создавать детские площадки, которые
                  будут стимулировать развитие вестибулярной
                  системы&nbsp;&mdash; это основа того, что голова ребенка
                  &laquo;подружится&raquo; с&nbsp;телом. Обязательные
                  элементы&nbsp;&mdash; горки, качели, карусели, встроенные
                  батуты, тарзанки. Чтобы ребенок научился спокойно сидеть
                  в&nbsp;школе 45&nbsp;минут, он&nbsp;сначала должен научиться
                  эффективно двигаться. Если дети мало двигаются
                  в&nbsp;дошкольном возрасте, потом из&nbsp;них получаются
                  невнимательные школьники.
                  <br />
                  <br />
                </p>
                <p>
                  Современные дети перегружены зрительной и&nbsp;слуховой
                  информацией, которая поступает с&nbsp;экранов электронных
                  устройств. В&nbsp;то&nbsp;же время они испытывают острую
                  нехватку тактильной, вестибулярной и&nbsp;мышечно-суставной
                  стимуляции, которая необходима для нормального развития.
                  И&nbsp;грамотно организованная городская среда может помочь
                  компенсировать этот дефицит. Для этого нужны объекты, которые
                  хочется потрогать, залезть на&nbsp;них, протиснуться
                  в&nbsp;какой-то лаз, спрыгнуть, перелезть, покачаться,
                  покрутиться. А&nbsp;также место, чтобы бегать и&nbsp;играть
                  в&nbsp;мяч.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.articleContent}>
        <ImageSwiper
          firstImg={image5_1}
          secondImg={image5_2}
          thirdImg={image5_3}
        />
        <h2 className={styles.articleContentTitle}>
          Не&nbsp;просто детские площадки, а&nbsp;плейхабы
        </h2>
        <div className={styles.articleContentItem}>
          <div className={styles.articleContentItemTextTop}>
            <p>
              Сейчас детские площадки не&nbsp;похожи на&nbsp;те, где играли
              нынешние родители двадцать и&nbsp;более лет назад. Выросли
              требования к&nbsp;безопасности, появились современные стандарты
              организации детских площадок для разных возрастных групп, которые
              разрабатываются в&nbsp;том числе с&nbsp;психологами
              и&nbsp;физиологами. Архитекторы считают, что необходимо создавать
              в&nbsp;жилых комплексах места для развития и&nbsp;проявления
              активности детей в&nbsp;разных форматах.
            </p>
            <p>
              &laquo;Это могут быть интересные площадки для игр и&nbsp;изучения
              мира вокруг с&nbsp;объемом: зеленые насаждения, спортивные
              площадки, горки и&nbsp;элементы геопластики. В&nbsp;таких
              пространствах дети взаимодействуют друг с&nbsp;другом, а&nbsp;это
              самый важный этап развития,&nbsp;&mdash; отмечает генеральный
              директор архбюро ИМАГО Сергей Остроух. &mdash;&nbsp;С&nbsp;одной
              стороны, на&nbsp;площадках должны быть места, где социализируются
              и&nbsp;играют дети разного возраста, с&nbsp;другой&nbsp;&mdash;
              нужно добавлять и&nbsp;отдельные зоны с&nbsp;игровыми форматами
              под разные возрасты&raquo;.
            </p>
            <ImageSwiper firstImg={image6_1} secondImg={image6_2} />
            <p>
              &laquo;Главные ценности современных детских площадок, плейхабов
              (PlayHub),&nbsp;&mdash; их&nbsp;уникальность
              и&nbsp;многообразность. Можно сказать, что в&nbsp;этом суть
              концепции playability, или &bdquo;бесконечной игры&ldquo;, которая
              лежит в&nbsp;их&nbsp;основе. Ведь PlayHub&nbsp;&mdash; это
              пространства, не&nbsp;ограниченные готовыми игровыми сценариями,
              а, наоборот, поощряющие и&nbsp;подталкивающие к&nbsp;созданию
              новых. Иными словами, дети каждый раз сами создают планы своих
              приключений, сочиняя свои правила, выбирая разные роли. Это
              оживляет игру, при этом побуждая к&nbsp;экспериментам
              и&nbsp;социализации, ведь многие элементы площадок раскрывают свой
              потенциал только при игре сообща. Каждый из&nbsp;трех уже
              построенных ПИК PlayHub&nbsp;&mdash; главная игровая точка
              притяжения жителей района. Это особенные пространства, насыщенные
              разнообразными вариантами активного отдыха и&nbsp;увлекательного
              досуга. По-настоящему любимое место для детей
              и&nbsp;их&nbsp;родителей, которое сохраняет актуальность
              и&nbsp;не&nbsp;надоедает со&nbsp;временем&raquo;,&nbsp;&mdash;
              отмечает Дарья Камышева.
            </p>
            <p>
              По&nbsp;словам Сергея Остроуха, не&nbsp;стоит забывать
              и&nbsp;о&nbsp;том, что иногда дети хотят спрятаться от&nbsp;лишней
              активности, поиграть в&nbsp;тишине, и&nbsp;на&nbsp;площадке
              у&nbsp;них должна быть такая возможность. Также важно дополнять
              детские площадки местами для творчества и&nbsp;давать детям
              материалы для &laquo;строительства&raquo;: это развивает
              воображение и&nbsp;побуждает детей чувствовать свою
              востребованность в&nbsp;мире вокруг. &laquo;Мы&nbsp;считаем, что
              необходимо в&nbsp;городскую среду привносить больше негородского:
              парки, зелень, спокойные места для отдыха, где ребенку будет проще
              постигать мир и&nbsp;&bdquo;слушать
              себя&ldquo;&raquo;,&nbsp;&mdash; говорит генеральный директор
              архбюро ИМАГО.
            </p>
          </div>
        </div>
      </section>

      <section className={styles.articleContent}>
        <h2 className={styles.articleContentTitle}>
          Безопасность детей в&nbsp;жилых кварталах
        </h2>
        <div className={styles.articleContentItem}>
          <div className={styles.articleContentItemTextTop}>
            <p>
              С&nbsp;точки зрения градостроительной политики при создании
              современных жилых кварталов важны высокие показатели качества
              городской среды, первый из&nbsp;которых&nbsp;&mdash; ощущение
              безопасности, поясняет Олег Шапиро из&nbsp;Wowhaus.
              &laquo;Когда-то считалось, что если родители спокойно отпускают
              детей гулять, значит, это благополучный и&nbsp;грамотно
              организованный город. В&nbsp;данном случае безопасность понимается
              в&nbsp;широком смысле: транспорт, освещенность, отсутствие
              заброшенных территорий, наличие приспособленных мест для
              игр&raquo;,&nbsp;&mdash; поясняет архитектор.
            </p>
            <p>
              Также в&nbsp;городе должен быть обеспечен безопасный путь
              школьников до&nbsp;школы и&nbsp;мест дополнительного образования.
              &laquo;Мы&nbsp;исследовали системы дополнительного образования
              в&nbsp;ЮВАО, и&nbsp;самым интересным оказалось, что до&nbsp;90%
              родителей отдают школьников в&nbsp;секции и&nbsp;кружки
              (художественные школы, музыкальные), расположенные недалеко
              от&nbsp;дома, &bdquo;на&nbsp;безопасном расстоянии&ldquo;.
              То&nbsp;есть в&nbsp;основном после школы все дети ходят
              в&nbsp;кружки своего района пешком. Пути до&nbsp;школ
              и&nbsp;ближайших кружков и&nbsp;секций&nbsp;&mdash; это отдельная
              градостроительная задача, которая решается в&nbsp;рамках концепции
              безопасного города&raquo;,&nbsp;&mdash; говорит Олег Шапиро.
              По&nbsp;его словам, для разных групп детей нужны разные условия:
              от&nbsp;0&nbsp;до&nbsp;2&nbsp;лет
              и&nbsp;от&nbsp;2&nbsp;до&nbsp;6&nbsp;&mdash; это безопасные
              качественные площадки, не&nbsp;только развлекающие,
              но&nbsp;и&nbsp;развивающие. Школьникам, которые самостоятельно
              передвигаются по&nbsp;улицам, нужно обеспечить безопасность этого
              передвижения.
            </p>
            <ImageSwiper
              firstImg={image7_1}
              secondImg={image7_2}
              thirdImg={image7_3}
            />
            <p>
              В&nbsp;Москве уже давно популярна концепция &laquo;дворы без
              машин&raquo;, где разделяются пешеходные и&nbsp;транспортные
              потоки. В&nbsp;своих проектах ПИК также отдает приоритет
              пешеходам: маршруты для людей и&nbsp;машин почти
              не&nbsp;пересекаются.
            </p>
            <p>
              &laquo;Очень важно, чтобы перемещаться по&nbsp;жилому району
              пешком было безопасно и&nbsp;удобно. Поэтому мы&nbsp;строим
              широкие тротуары, а&nbsp;дворы полностью закрываем для
              автомобилей. За&nbsp;периметром двора мы&nbsp;используем
              инструменты по&nbsp;успокоению автомобильного движения: применяем
              приподнятые пешеходные переходы и&nbsp;выделяем пересечения
              с&nbsp;дорогами контрастным мощением. Также предусмотрен ряд
              антипарковочных мер вдоль пешеходных связей: клумбы с&nbsp;двойным
              бортом, зеленые насаждения и&nbsp;природные элементы&nbsp;&mdash;
              настоящие валдайские валуны. Все это делает среду более
              дружелюбной к&nbsp;человеку и&nbsp;способствует его безопасному
              и&nbsp;комфортному передвижению. Кроме того, любой жилой район
              ПИК&nbsp;&mdash; это единая инклюзивная безбарьерная среда, без
              крутых лестниц, порогов и&nbsp;пандусов. Максимум удобства всем:
              и&nbsp;детям на&nbsp;самокатах, и&nbsp;велосипедистам,
              и&nbsp;молодым мамам с&nbsp;колясками, и&nbsp;пожилым жителям,
              и&nbsp;людям с&nbsp;ограниченными возможностями
              здоровья&raquo;,&nbsp;&mdash; поясняет Дарья Камышева.
            </p>
            <p>
              Внутри своих дворов-парков компания строит детские площадки
              с&nbsp;игровыми зонами для разных возрастных групп, оборудованием
              из&nbsp;экологичных материалов и&nbsp;покрытием из&nbsp;резиновой
              крошки, на&nbsp;которую мягко падать. Дворы закрыты не&nbsp;только
              от&nbsp;машин, но&nbsp;и&nbsp;от&nbsp;посторонних, а&nbsp;рядом
              с&nbsp;площадками организованы зоны отдыха для родителей, откуда
              можно наблюдать за&nbsp;ребенком.
            </p>
            <p>
              Таким образом, социальная инфраструктура ПИК отвечает потребностям
              детей в&nbsp;играх и&nbsp;развитии, а&nbsp;также запросу родителей
              на&nbsp;обеспечение безопасности ребенка.
            </p>
          </div>
        </div>
      </section>
      <section
        className={styles.articleProduct}
        style={{ background: '#E3D3F7' }}
      >
        <section className={styles.articleContent}>
          <Playhub withoutTable />
        </section>
      </section>
      <section className={styles.articleContent}>
        <Articles thirdArticle />
      </section>
      <Feedback />
    </Layout>
  )
}
