import React from 'react'

import imgClose from '../../../assets/images/icons/close-btn.svg'
import styles from '../footer.module.scss'
import { HoverBlockTypes } from './types'

export const FooterInfoHoverBlock = ({ closeInfo }: HoverBlockTypes) => {
  return (
    <div className={styles.infoHoverBlock}>
      <div className={styles.infoHoverBlockClose} onClick={closeInfo}>
        <img src={imgClose} alt={''} />
      </div>
      <h4>Над проектом работали:</h4>
      <p className={styles.infoHoverBlockDescription}>
        директор отдела спецпроектов Полина&nbsp;Абдуллина, digital директор
        Михаил&nbsp;Быков, ведущий дизайнер Дарья&nbsp;Полевщикова, дизайнер
        Дарья&nbsp;Островская, технический директор Антон&nbsp;Шереметьев,
        менеджеры проекта Екатерина&nbsp;Головлева и Кристина&nbsp;Кучер.
      </p>
      <span>© АО «РОСБИЗНЕСКОНСАЛТИНГ», 1995–2023</span>
    </div>
  )
}
