import React from 'react'

export const ArrowLeftIcon = () => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path stroke='currentColor' strokeWidth='2' d='M6 9 2 5l4-4'></path>
    </svg>
  )
}
