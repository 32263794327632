import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import ReactGA from 'react-ga'
import { IoIosArrowDown } from 'react-icons/io'
import ym from 'react-yandex-metrika'

import { getAnimationFourParams } from '../../../helpers/animations'
import { pluralize } from '../../../helpers/common'
import { useProjects } from '../../../hooks/useProjects'
import { fetchCitiesRequest } from '../../../store/cities/actions'
import { fetchObjectsRequest } from '../../../store/objects/actions'
import { ProjectCard } from '../../ProjectCard/ProjectCard'
import { PlacesMap } from '../../UI/PlaceMap/PlacesMap'
import { RoomsFilter } from '../../UI/RoomsFilter/RoomsFilter'
import styles from './newBuildings.module.scss'
import { ProjectsData } from './NewBuildings.types'

export const NewBuildings = () => {
  const {
    items,
    cities: { items: citiesItems },
    showMore,
    setShowMore,
    openCitiesBlock,
    handleOpenCitiesBlock,
    getCity,
    handleOpenCity,
    getRooms,
    handleOPenAll,
    handleOPenAll1,
    dispatch,
    setGetRooms,
    rangeValue,
    projectsData,
    declineCity,
  } = useProjects()

  const filterRef = useRef<HTMLDivElement>(null)

  const [mapState, setMapState] = useState(false)
  const handleCloseSix = () => {
    if (showMore !== 6) {
      setShowMore(6)
    }
    filterRef.current?.scrollIntoView()
    ReactGA.ga('send', 'event', 'main', 'projects bottom list', 'hide')
    ym('reachGoal', 'projects list click', {
      'projects list click': {
        bottom: {
          'click type': 'hide',
        },
      },
    })
  }

  const handleToggleMapMobile = () => {
    setMapState(prev => !prev)
    filterRef.current?.scrollIntoView()
  }
  const handleToggleMap = () => {
    setMapState(prev => !prev)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value }: any = event.target
    setGetRooms((prevValues: string[]) => {
      if (prevValues.includes(value)) {
        return prevValues.filter(val => val !== value)
      }

      return [...prevValues, value]
    })
    {
      ReactGA.ga('send', 'event', 'main', 'filters click', value)
    }
    ym('reachGoal', 'filters click', {
      'filters click': {
        main: {
          'filters name': value,
        },
      },
    })
  }

  useEffect(() => {
    const filters = {
      city: getCity,
      rooms: getRooms.join('-or-'),
      priceFrom: rangeValue.sliderValues[0],
      priceTo: rangeValue.sliderValues[1],
    }
    dispatch(fetchObjectsRequest(filters))
    dispatch(fetchCitiesRequest())
  }, [getCity, getRooms])

  return (
    <motion.div
      {...getAnimationFourParams}
      className={String(styles.newBuildingWrapper)}
      id='#newBuildings'
    >
      <h2 className={styles.cityMainTitle}>Новостройки</h2>
      <h2 className={styles.cityTitle} onClick={handleOpenCitiesBlock}>
        в {declineCity(getCity)}
        <IoIosArrowDown />
      </h2>
      {openCitiesBlock && (
        <div className={styles.citiesBlock}>
          {citiesItems?.map((item: string) => (
            <p
              className={item === getCity && styles.activeCity}
              onClick={() => handleOpenCity(item)}
            >
              {declineCity(item)}
            </p>
          ))}
        </div>
      )}
      <div ref={filterRef} className={styles.filtersBlock}>
        <RoomsFilter
          map={mapState}
          toggleMap={handleToggleMap}
          filterChange={handleCheckboxChange}
        />
        <p>
          <span>
            {pluralize(
              items?.length,
              'Подобран',
              'Подобрано',
              'Подобрано',
              true
            )}
          </span>
          &ensp;
          <span>
            {items?.length > 0
              ? pluralize(items?.length, 'проект', 'проекта', 'проектов')
              : 'нет проектов'}
          </span>
        </p>
      </div>

      <PlacesMap mapState={mapState} city={getCity} />

      <div
        className={`${styles.projectsList} ${mapState ? 'is-hidden' : ''}`}
        id='cardsBlock'
      >
        <div className={styles.projectsWrapper}>
          {projectsData?.slice(0, showMore).map((item: ProjectsData) => {
            return (
              <div key={item.UF_BUILDING_NAME}>
                <ProjectCard data={item} />
              </div>
            )
          })}
        </div>
        {/*TODO вынести в отдельный компонент */}
        {items?.length > 6 && (
          <>
            {showMore === 12 && showMore < items?.length && (
              <button
                className={styles.showMoreBtn}
                onClick={() => handleOPenAll1(items?.length)}
              >
                Показать все
              </button>
            )}

            {showMore === 6 && (
              <button className={styles.showMoreBtn} onClick={handleOPenAll}>
                Показать ещё
              </button>
            )}

            {showMore === 4 && (
              <button
                className={styles.showMoreBtn}
                onClick={handleToggleMapMobile}
              >
                Показать ещё
              </button>
            )}

            {showMore >= items?.length && (
              <button className={styles.showMoreBtn} onClick={handleCloseSix}>
                Скрыть все
              </button>
            )}
          </>
        )}
      </div>
    </motion.div>
  )
}
