export const cityCoordinates = [
  { name: 'Москва и область', coordinates: [55.755826, 37.6172999] },
  {
    name: 'Санкт-Петербург и область',
    coordinates: [59.9342802, 30.3350986],
  },
  { name: 'Благовещенск', coordinates: [50.290658, 127.527173] },
  { name: 'Владивосток', coordinates: [43.115536, 131.885485] },
  { name: 'Екатеринбург', coordinates: [56.838011, 60.597465] },
  { name: 'Казань', coordinates: [55.8304307, 49.0660806] },
  { name: 'Калуга', coordinates: [54.551858, 36.285097] },
  { name: 'Нижний Новгород', coordinates: [56.296504, 43.936059] },
  { name: 'Новороссийск', coordinates: [44.723918, 37.768971] },
  { name: 'Обнинск', coordinates: [55.094389, 36.612178] },
  { name: 'Ростов-на-Дону', coordinates: [47.222078, 39.720349] },
  { name: 'Тюмень', coordinates: [57.161297, 65.525017] },
  { name: 'Южно-Сахалинск', coordinates: [46.959179, 142.738023] },
  { name: 'Ярославль', coordinates: [57.626569, 39.893787] },
  { name: 'Хабаровск', coordinates: [48.480223, 135.071917] },
]

// getCityCoordinates функция передающая координаты на карту
// и позволяющая ей перемещаться в выбранный город
export const getCityCoordinates = (city: string) => {
  const cityData = cityCoordinates.find(item => item.name === city)
  return cityData ? cityData.coordinates : null
}
