import React from 'react'

export const PhoneIcon = () => {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.91951 6.32258L7.4291 0.923697C7.00166 -0.625312 3.75531 0.0723735 2.62979 0.858786C-2.12378 4.18096 0.522022 10.8851 3.11276 14.7658C7.85249 21.072 12.4959 24.4379 17.0431 24.8635C20.653 25.5019 24.9203 23.9235 24.4891 19.6477C24.4303 19.0637 24.2933 18.6055 24.078 18.2733C23.7234 17.7793 21.6458 17.0906 17.8452 16.2074C17.1161 16.0776 16.3598 16.4936 15.5765 17.4556C15.4669 17.5944 15.3468 17.7244 15.2172 17.8447C14.6171 18.3962 14.2433 18.4984 13.542 18.1248C11.4011 16.9831 7.66101 12.3173 6.79634 10.8743C6.54187 10.2813 6.72977 9.7573 7.36003 9.30245C8.5907 8.48553 9.11052 7.49224 8.91951 6.32258Z'
        fill='#141414'
      />
    </svg>
  )
}
