import React from 'react'
interface WalkManProps {
  className?: string
}

export const WalkMan = ({ className }: WalkManProps) => {
  return (
    <svg
      width='8'
      height='12'
      viewBox='0 0 8 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.25089 2.24414C4.87239 2.24414 5.37622 1.74177 5.37622 1.12207C5.37622 0.50237 4.87239 0 4.25089 0C3.62939 0 3.12556 0.50237 3.12556 1.12207C3.12556 1.74177 3.62939 2.24414 4.25089 2.24414ZM2.20296 6.59277L1.96851 8.30702L0.2805 11.0498C0.0929402 11.3459 0.18672 11.7199 0.48368 11.9069C0.59309 11.9693 0.7025 12.0004 0.81191 12.0004C1.0151 12.0004 1.23391 11.8914 1.34332 11.7043L3.10949 8.83688C3.15638 8.75896 3.18764 8.68104 3.20327 8.58753L3.35957 7.43431L2.20296 6.59277ZM5.95458 4.11495L7.5801 4.70715C7.8271 4.78926 7.9856 5.01264 7.9956 5.25766C7.9989 5.33817 7.9861 5.42101 7.9552 5.50195C7.8458 5.82922 7.4863 6.00065 7.1581 5.87597L5.37628 5.22143C5.2825 5.19027 5.18872 5.12793 5.11057 5.03442L4.65731 4.47339L4.25094 6.15649L6.0134 7.5C6.1697 7.60909 6.2635 7.7961 6.2635 7.9987V11.3727C6.2635 11.7156 5.98218 11.9961 5.63832 11.9961C5.29447 11.9961 5.01313 11.7156 5.01313 11.3727V8.31038L3.40693 7.09155L2.25033 6.26558L2.73485 4.09937L2.06277 4.39547L1.17578 5.9539C1.05075 6.15649 0.84756 6.26558 0.62874 6.26558C0.51934 6.26558 0.40993 6.25 0.31615 6.18766C0.11526 6.07169 0.000200285 5.85588 2.85368e-07 5.63672C-9.97146e-05 5.5319 0.0260801 5.42632 0.0817001 5.33052L1.07809 3.60067C1.20473 3.40426 1.43269 3.30888 1.63655 3.22359C1.65915 3.21414 1.68145 3.20481 1.70328 3.19548L3.20374 2.55652C3.31315 2.49418 3.43819 2.44743 3.56322 2.43185C3.70389 2.41626 4.25094 2.46302 4.39161 2.4786C4.69398 2.52885 4.88464 2.78163 5.06182 3.01653C5.10445 3.07304 5.14629 3.12852 5.18872 3.17989C5.31376 3.33574 5.44271 3.49158 5.57165 3.64742C5.7006 3.80327 5.82954 3.95911 5.95458 4.11495Z'
        fill='black'
      />
    </svg>
  )
}
