import moment from 'moment'

export const moscow = 'Москва и область'
export const spb = 'Санкт-Петербург и область'

export const addWordToRenovation = (str: string) => {
  if (str === 'Без отделки' || str === 'White box') {
    return str
  } else {
    return str + ' отделка'
  }
}

export const handleSettlementDate = (month: string, year: string) => {
  const date = month + ' ' + year
  const dateFormat = 'DD MMMM YYYY'
  moment.locale('ru')
  return moment(date, dateFormat).format()
}
