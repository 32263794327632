import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'

import styles from '../Main/Selections/selections.module.scss'
import { SelectionCardTypes } from './SelectionCard.types'

export const SelectionCard = ({
  linkTo,
  cardClick,
  title,
  description,
  image,
}: SelectionCardTypes) => {
  return (
    <Link
      className={styles.selectionCard}
      key={linkTo}
      onClick={() => cardClick}
      to={`/collections/${linkTo}`}
    >
      <img className={styles.selectionImg} src={image} alt='BG' />
      <div className={styles.cardDescription}>
        <h3>{title}</h3>
        <p>{description}</p>
        <div className={styles.cardBtn}>
          Читать <IoIosArrowForward />
        </div>
      </div>
    </Link>
  )
}
