import { motion } from 'framer-motion'
import React from 'react'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ym from 'react-yandex-metrika'

import selection4 from '../../../assets/images/selection4.jpg'
import { getAnimationFourParams } from '../../../helpers/animations'
import { getSelections } from '../../../store/selections/selectors'
import { Button } from '../../UI/Button/Button'
import { Table } from '../../UI/Table/Table'
import { ApartsmentsTopTypes } from '../ApartmentsTop/ApartsmentsTop.types'
import styles from '../FamilyFormat/familyFormat.module.scss'

export const Playhub = ({ withoutTable }: ApartsmentsTopTypes) => {
  const navigate = useNavigate()
  const selections: any = useSelector(getSelections)
  const handleAnalyticClick = (name: string) => {
    ReactGA.ga('send', 'event', 'main', 'collection click', name)
    ym('reachGoal', 'collection click', {
      'collection click': {
        'collection name': name,
      },
    })
    navigate('/collections/4')
  }

  return (
    <motion.div
      {...getAnimationFourParams}
      className={styles.apartmentsWrapper}
      id='#playHub'
    >
      <div className={styles.investmentsBlock} style={{ padding: '25px 0' }}>
        <img
          className={styles.investmentsImg}
          src={String(selection4)}
          alt='Playhub'
        />
        <div className={styles.investmentsDescription}>
          <h3>Квартиры в&nbsp;проектах с&nbsp;PlayHub и&nbsp;LocalPlay</h3>
          <p>Место для игры и&nbsp;отдыха</p>
          <Button
            content='Интересно'
            arrowRight
            onClick={() =>
              handleAnalyticClick('Квартиры в проектах с PlayHub и LocalPlay')
            }
          />
        </div>
      </div>
      {!withoutTable && (
        <Table items={selections[4]?.items} position='bottom' isSettlement />
      )}
    </motion.div>
  )
}
