import { selectionImages } from '../components/collectionPage'

export const selectionCardData = [
  {
    id: '1',
    title: 'Квартиры в готовых проектах',
    description: 'Дома с инфраструктурой для жизни',
    image: selectionImages['1'],
  },
  {
    id: '2',
    title: 'Семейный топ: подборка больших квартир',
    description: 'Жильё для больших семей и любителей простора',
    image: selectionImages['2'],
  },
  {
    id: '3',
    title: 'Без машины \nкуда угодно',
    description: 'Квартиры рядом с метро',
    image: selectionImages['3'],
  },
  {
    id: '4',
    title: 'Квартиры в проектах с PlayHub и LocalPlay',
    description: 'Место для игры и отдыха',
    image: selectionImages['4'],
  },
  {
    id: '5',
    title: 'Квартиры с французскими балконами',
    description: 'Дом, где всегда много света',
    image: selectionImages['5'],
  },
  {
    id: '6',
    title: 'Дома рядом с вузами',
    description: 'Дорога займет не больше 30 минут',
    image: selectionImages['6'],
  },
  {
    id: '7',
    title: 'Квартиры с высокими потолками',
    description: 'Всё о высоких потолках',
    image: selectionImages['7'],
  },
]
