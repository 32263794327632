import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'

import { AppRouter } from '../components/AppRouter/AppRouter'
import { usePageViews } from '../hooks/usePageViews'

export const App = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.initialize(
      [
        {
          trackingId: 'UA-90428516-32',
          gaOptions: {
            name: 'rbcspec',
          },
        },
        {
          trackingId: 'UA-232131609-50',
        },
      ],
      { debug: true, alwaysSendToDefaultTracker: false }
    )
    ReactGA.ga('rbcspec.send', 'pageview')
    ReactGA.pageview(location.pathname + location.search + location.hash)
  }, [])

  usePageViews()

  return <AppRouter />
}
