import { all, call, put, takeLatest } from 'redux-saga/effects'

import request from '../../api/api'
import { fetchCitiesFailure, fetchCitiesSuccess } from './actions'
import { FETCH_CITIES_REQUEST } from './actionTypes'

function* fetchCitiesSaga({ payload }: any): any {
  try {
    const response = yield call(request.get, '/location/cities', payload)

    yield put(
      fetchCitiesSuccess({
        cities: response.data,
      })
    )
  } catch (e: any) {
    yield put(
      fetchCitiesFailure({
        error: e.message,
      })
    )
  }
}

function* citiesSaga() {
  yield all([takeLatest(FETCH_CITIES_REQUEST, fetchCitiesSaga)])
}

export default citiesSaga
