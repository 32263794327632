import React from 'react'
import ReactGA from 'react-ga'
import { Link } from 'react-router-dom'
import ym from 'react-yandex-metrika'

import styles from '../../Header/header.module.scss'
import { PhoneIcon } from '../../icons/PhoneIcon'
import { BurgerMenuTypes } from './BurgerMenu.types'

export const BurgerMenu = ({ handleClickBurger }: BurgerMenuTypes) => {
  const handleLinkClick = (name: string) => {
    ReactGA.ga('send', 'event', 'main', 'filters click', name)
    ym('reachGoal', 'filters click', {
      'filters click': {
        main: {
          'filters name': name,
        },
      },
    })

    handleClickBurger()
  }

  return (
    <div className={styles.menu__wrapper}>
      <div className={styles.menu}>
        <Link
          className={styles.menu__item}
          to='/#LifeWest'
          onClick={() => handleLinkClick('Жизнь на западе Москвы')}
        >
          Жизнь на западе Москвы
        </Link>
        <Link
          className={styles.menu__item}
          to='/#newBuildings'
          onClick={() => handleLinkClick('Все новостройки')}
        >
          Все новостройки
        </Link>
        <Link
          className={styles.menu__item}
          to='/#nearParks'
          onClick={() => handleLinkClick('Квартиры у парков')}
        >
          Квартиры у парков
        </Link>
        <Link
          className={styles.menu__item}
          to='/#selections'
          onClick={() => handleLinkClick('Статьи и подборки')}
        >
          Статьи и подборки
        </Link>
        <Link
          className={styles.menu__item}
          to='/#PIK'
          onClick={() => handleLinkClick('Ипотека в ПИК')}
        >
          Ипотека в ПИК
        </Link>
      </div>
      <div className={styles.phone}>
        <a
          href='tel:+74958592182'
          onClick={() => {
            ReactGA.ga(
              'send',
              'event',
              'main',
              'filters click',
              'Связаться с нами'
            )
          }}
        >
          <PhoneIcon />
        </a>
      </div>
    </div>
  )
}
