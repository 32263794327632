import React from 'react'
import ReactGA from 'react-ga'
import { Carousel } from 'react-responsive-carousel'
import ym from 'react-yandex-metrika'

import bannerBg1 from '../../../assets/images/banners/1.jpg'
import bannerBg2 from '../../../assets/images/banners/2.jpg'
import styles from './programsCarousel.module.scss'
import { PageProps } from './ProgramsCarousel.types'

export const ProgramsCarousel = ({ pageType }: PageProps) => {
  const firstLink =
    'https://www.pik.ru/search?currentBenefit=pyat-let-bez-procentov-2&utm_source=rbc&utm_medium=display&utm_content=mortgage'
  const secondLink =
    'https://www.pik.ru/search?currentBenefit=semejnaya-4-5&utm_source=rbc&utm_medium=display&utm_content=mortgage'
  const thirdLink =
    'https://www.pik.ru/search?currentBenefit=gotovoe-8-5&utm_source=rbc&utm_medium=display&utm_content=mortgage'
  const handleCarouselClick = (name: string) => {
    ReactGA.ga(
      'send',
      'event',
      'client link',
      `${pageType} PIK mortgage link`,
      name
    )
    ReactGA.ga('rbcspec.send', 'event', 'client link', 'click')
    ym('reachGoal', 'client link', {
      'client link': {
        'main PIK mortgage link': {
          link: name,
        },
      },
    })
  }

  return (
    <div className={styles.carouselWrapper}>
      <Carousel
        useKeyboardArrows={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        className={styles.carsWrapper}
      >
        <a
          className={styles.firstCard}
          href={firstLink}
          target={'_blank'}
          onClick={() =>
            handleCarouselClick('Пять лет без процентов по ипотеке')
          }
        >
          <div
            className={styles.carsWrapperBg}
            style={{ backgroundImage: `url(${bannerBg1})` }}
          ></div>
          <p>
            Пять лет
            <br />
            без процентов
            <br />
            по ипотеке
          </p>
        </a>
        <a
          className={styles.secondCard}
          href={secondLink}
          target={'_blank'}
          onClick={() => handleCarouselClick('Ипотека 1% до 15 июня')}
        >
          <div
            className={styles.carsWrapperBg}
            style={{ backgroundColor: '#FFF' }}
          ></div>
          <p>
            Ипотека <span className={styles.carsWrapperNumb}>1%</span>
            <span className={styles.carsWrapperDate}>до 15 июня</span>
          </p>
          <div className={styles.carsWrapperButton}>Подробнее</div>
        </a>
        <a
          className={styles.thirdCard}
          href={thirdLink}
          target={'_blank'}
          onClick={() => handleCarouselClick('Квартиры в&nbsp;готовых домах')}
        >
          <div
            className={styles.carsWrapperBg}
            style={{ backgroundImage: `url(${bannerBg2})` }}
          ></div>
          <p>
            Квартиры
            <br />
            в&nbsp;готовых домах
          </p>
        </a>
      </Carousel>
    </div>
  )
}
