import { motion } from 'framer-motion'
import React from 'react'

import { getAnimationFourParams } from '../../../helpers/animations'
import styles from './feedback.module.scss'

export const Feedback = () => {
  return (
    <motion.div
      {...getAnimationFourParams}
      className={styles.feedbackWrapper}
      id='#feedback'
    >
      <div className={styles.formBlock}>
        <section className='feedback'>
          <iframe src='https://www.pik.ru/callback' />
        </section>
      </div>
    </motion.div>
  )
}
