import { all, fork } from 'redux-saga/effects'

import citiesSaga from './cities/sagas'
import objectsSagas from './objects/sagas'
import selectionsSagas from './selections/sagas'

export function* rootSaga() {
  yield all([fork(citiesSaga)])
  yield all([fork(objectsSagas)])
  yield all([fork(selectionsSagas)])
}
