import { cityIn } from 'lvovich'
import { useState } from 'react'
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from 'react-redux'
import ym from 'react-yandex-metrika'

import { moscowProjectsPictures } from '../components/Main/newBuildings/images/projectPictures'
import {
  PopupProps,
  ProjectsData,
} from '../components/Main/newBuildings/NewBuildings.types'
import { RangeValue } from '../components/UI/RangeSlider/RangePicker.types'
import { getCities } from '../store/cities/selectors'
import { getObjects } from '../store/objects/selectors'

export const useProjects = () => {
  const isMobile = window.screen.width <= 768
  const [showMore, setShowMore] = useState(isMobile ? 4 : 6)
  const [openCitiesBlock, setOpenCitiesBlock] = useState(false)
  const [getCity, setGetCity] = useState('Москва и область')
  const [getRooms, setGetRooms] = useState<string[]>([])
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { items } = useSelector(getObjects)
  const cities = useSelector(getCities)

  const openMoreCards = () => {
    setShowMore(showMore + 6)
  }

  const openAllCards = (i: number) => {
    setShowMore(showMore + i)
    ReactGA.ga('send', 'event', 'main', 'projects top list', 'show')
    ym('reachGoal', 'projects top list', {
      'projects top list': {
        top: {
          'click type': 'show',
        },
      },
    })
  }

  const handleOpenCity = (city: string) => {
    setGetCity(city)
    setOpenCitiesBlock(false)
    {
      ReactGA.ga('send', 'event', 'main', 'region filters click', city)
    }
    ym('reachGoal', 'region filters click', {
      'region filters click': {
        main: {
          'filters name': city,
        },
      },
    })
  }

  const handleOpenCitiesBlock = () => {
    setOpenCitiesBlock(!openCitiesBlock)
  }

  const [rangeValue, setRangeValue] = useState<RangeValue>({
    sliderValues: [1093102, 48304570],
  })

  const [mapPopupState, setMapPopupState] = useState<PopupProps>({})

  const handleCloseMapPopup = () => {
    setMapPopupState({})
  }

  const handlePlacemarkClick = (data: PopupProps) => {
    setMapPopupState((prev: any) => ({
      ...prev,
      ...data,
    }))
  }

  const newArr = items?.map((item: ProjectsData) => {
    const matchingItem = moscowProjectsPictures.find(
      x => x.UF_BUILDING_NAME === item.UF_BUILDING_NAME
    )
    return {
      ...item,
      UF_IMAGE: matchingItem?.UF_IMAGE || item.UF_IMAGE,
    }
  })

  const projectsData = getCity === 'Москва и область' ? newArr : items

  const declineCity = (city: string) => {
    return cityIn(city)
  }

  return {
    showMore,
    openCitiesBlock,
    items,
    cities,
    getCity,
    getRooms,
    projectsData,
    declineCity,
    setShowMore,
    handleOpenCitiesBlock,
    handleOpenCity,
    handleOPenAll1: openAllCards,
    handleOPenAll: openMoreCards,
    dispatch,
    setGetRooms,
    rangeValue,
    setRangeValue,
    mapPopupState,
    handleCloseMapPopup,
    handlePlacemarkClick,
  }
}
