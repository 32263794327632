import React, { ChangeEvent, useCallback, useState } from 'react'

import styles from './checkBoxButton.module.scss'
import { ICheckBoxProps } from './CheckBoxButton.types'

export const CheckBoxButton = ({
  label,
  value,
  onChange,
  width,
  borderRadius,
}: ICheckBoxProps) => {
  const [active, setActive] = useState<boolean>(true)
  const isMobile = window.screen.width <= 768

  const clickHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setActive(!active)
    onChange(e)
  }

  const mobileStyles =
    isMobile && active
      ? styles.activeLabelStyleMobile
      : styles.inactiveLabelStyleMobile

  const checkBoxLabelStyleHandler = useCallback(
    (state: boolean) => {
      if (state) {
        return styles.activeLabelStyle
      }
      return styles.inactiveLabelStyle
    },
    [active]
  )

  return (
    <label>
      <input
        type='checkbox'
        style={{ display: 'none' }}
        onChange={e => clickHandler(e)}
        value={value}
      />
      <span
        style={{ width: width, borderRadius: borderRadius }}
        className={isMobile ? mobileStyles : checkBoxLabelStyleHandler(active)}
      >
        {label}
      </span>
    </label>
  )
}
