import { motion } from 'framer-motion'
import React from 'react'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ym from 'react-yandex-metrika'

import selection8 from '../../../assets/images/selection8.jpg'
import { getAnimationFourParams } from '../../../helpers/animations'
import { getSelections } from '../../../store/selections/selectors'
import { Button } from '../../UI/Button/Button'
import { Table } from '../../UI/Table/Table'
import styles from '../FamilyFormat/familyFormat.module.scss'

export const NearParks = () => {
  const navigate = useNavigate()
  const selections: any = useSelector(getSelections)
  const handleAnalyticClick = (name: string) => {
    ReactGA.ga('send', 'event', 'main', 'collection click', name)
    ym('93597994', 'reachGoal', 'collection click', {
      'collection click': {
        'collection name': name,
      },
    })
    navigate('/collections/8')
  }

  return (
    <motion.div
      {...getAnimationFourParams}
      className={styles.apartmentsWrapper}
      id='#nearParks'
    >
      <div className={styles.investmentsBlock}>
        <img className={styles.investmentsImg} src={selection8} alt='Playhub' />
        <div className={styles.investmentsDescription}>
          <h3>Квартиры рядом с&nbsp;парками</h3>
          <p>Жить в&nbsp;городе рядом с&nbsp;природой</p>
          <Button
            content='Интересно'
            arrowRight
            onClick={() => handleAnalyticClick('Квартиры рядом с парками')}
          />
        </div>
      </div>
      <Table
        items={selections[8]?.items}
        position='bottom'
        isSettlement
        isFirstSelection
      />
    </motion.div>
  )
}
