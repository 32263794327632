import 'rc-slider/assets/index.css'
import './style.css'

import Range from 'rc-slider'
import React, { ChangeEvent, useState } from 'react'

import { formatNumberWithThousands } from '../../../helpers/common'
import { useProjects } from '../../../hooks/useProjects'
import { fetchObjectsRequest } from '../../../store/objects/actions'
import styles from './rangePicker.module.scss'
import { InputValues, RangeValue } from './RangePicker.types'

type InputValuesKeys = keyof InputValues

export const RangePicker = () => {
  const { getCity, getRooms, dispatch, rangeValue, setRangeValue } =
    useProjects()

  const [inputValue, setInputValue] = useState<InputValues>({
    min: '',
    max: '',
  })

  const handleSubmitFilter = (value: number[]) => {
    const filters = {
      city: getCity,
      rooms: getRooms,
      priceFrom: String(value[0]).split(' ').join(''),
      priceTo: String(value[1]).split(' ').join(''),
    }

    dispatch(fetchObjectsRequest(filters))
  }

  const handleRangeChange = (sliderValues: number[] | number) => {
    const values = sliderValues as number[]
    setRangeValue({ sliderValues } as RangeValue)
    setInputValue({
      min: values[0].toString(),
      max: values[1].toString(),
    })
  }

  const handleChangeInput = (
    e: ChangeEvent<HTMLInputElement>,
    type: InputValuesKeys
  ) => {
    const inputValue = e.target.value
    setInputValue(prevState => ({ ...prevState, [type]: inputValue }))

    const newArr = [...rangeValue.sliderValues]

    const index = type === 'min' ? 0 : 1
    newArr[index] = inputValue
    setRangeValue({ sliderValues: newArr as number[] })
  }

  const minInputValue = formatNumberWithThousands(inputValue.min)
  const maxInputValue = formatNumberWithThousands(inputValue.max)

  return (
    <div className={styles.rangeFilter}>
      <div className={styles.rageFields}>
        <div className={styles.rageFieldStart}>
          <label>от</label>
          <input
            onChange={e => handleChangeInput(e, 'min')}
            value={minInputValue}
            onBlur={() =>
              handleSubmitFilter(rangeValue.sliderValues as number[])
            }
            placeholder='1 093 102'
          />
        </div>
        <div className={styles.rageFieldEnd}>
          <label>до</label>
          <input
            onChange={e => handleChangeInput(e, 'max')}
            value={maxInputValue}
            onBlur={() =>
              handleSubmitFilter(rangeValue.sliderValues as number[])
            }
            placeholder='48 304 570'
          />
        </div>
      </div>
      <div className={styles.rangeSlider}>
        <Range
          range
          onChange={handleRangeChange}
          value={rangeValue.sliderValues as number[]}
          step={100}
          min={1093102}
          max={48304570}
          onAfterChange={value => {
            handleSubmitFilter(value as number[])
          }}
        />
      </div>
    </div>
  )
}
