import {
  FETCH_CITIES_FAILURE,
  FETCH_CITIES_REQUEST,
  FETCH_CITIES_SUCCESS,
} from './actionTypes'
import { CitiesActions, CitiesState } from './types'

const initialState: CitiesState = {
  pending: false,
  cities: { items: [] },
  error: null,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action: CitiesActions) => {
  switch (action.type) {
    case FETCH_CITIES_REQUEST:
      return {
        ...state,
        pending: true,
      }
    case FETCH_CITIES_SUCCESS:
      return {
        ...state,
        pending: false,
        cities: action.payload.cities,
        error: null,
      }
    case FETCH_CITIES_FAILURE:
      return {
        ...state,
        pending: false,
        todos: [],
        error: action.payload.error,
      }
    default:
      return {
        ...state,
      }
  }
}
