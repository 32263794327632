import { combineReducers } from 'redux'

import citiesReducer from './cities/reducer'
import firstLoadReducer from './firstLoad/reducer'
import objectsReducer from './objects/reducer'
import selectionsReducer from './selections/reducer'

const rootReducer = combineReducers({
  cities: citiesReducer,
  objects: objectsReducer,
  selections: selectionsReducer,
  firstLoad: firstLoadReducer,
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
