import Share from 'ninelines-sharing'
import { useState } from 'react'
import ReactGA from 'react-ga'
import ym from 'react-yandex-metrika'

import imgShareOk from '../../assets/images/icons/share-ok.svg'
import imgShareTg from '../../assets/images/icons/share-tg.svg'
import imgShareVk from '../../assets/images/icons/share-vk.svg'
import { HeartLogo } from '../icons/HeartLogo'
import { InfoLogo } from '../icons/InfoLogo'
import { FooterAdvertisingHover } from './components/FooterAdvertisingHover'
import { FooterInfoHoverBlock } from './components/FooterInfoHoverBlock'
import styles from './footer.module.scss'

export const Footer = () => {
  const [active, setActive] = useState(false)
  const [active2, setActive2] = useState(false)

  const handleShareClick = (name: string) => {
    const url = location.origin + location.pathname
    ReactGA.ga('send', 'event', 'share', 'project share', name)
    ym('reachGoal', 'share', {
      share: {
        'project share': {
          'social-name': name,
        },
      },
    })
    Share[name](url)
  }

  const handleClickOpenInfo = () => {
    setActive(!active)
    if (!active) {
      document.documentElement.classList.add('info-block-open')
    }
  }

  const handleClickCloseInfo = () => {
    setActive(!active)
    if (!active) {
      document.documentElement.classList.remove('info-block-open')
    }
  }

  const handleClickOpenAdvertising = () => {
    setActive2(!active)
    if (!active2) {
      document.documentElement.classList.add('advertising-block-open')
    }
  }

  const handleClickCloseAdvertising = () => {
    setActive2(prev => !prev)
    if (!active2) {
      document.documentElement.classList.remove('advertising-block-open')
    }
  }

  return (
    <div className={styles.footer}>
      <div className={styles.shareMobile}>
        <div className={styles.shareMobileText}>Поделится:</div>
        <div className={styles.shareMobileButtons}>
          <button
            onClick={() => {
              handleShareClick('vk')
            }}
          >
            <img src={imgShareVk} alt={''} />
          </button>
          <button
            onClick={() => {
              handleShareClick('ok')
            }}
          >
            <img src={imgShareOk} alt={''} />
          </button>
          <button
            onClick={() => {
              handleShareClick('tg')
            }}
          >
            <img src={imgShareTg} alt={''} />
          </button>
        </div>
      </div>
      <div className={styles.infoBlock}>
        <InfoLogo />
        <div
          className={`${styles.infoBlockMob} ${
            active ? styles.activeInfoBlock : ''
          }`}
          onClick={handleClickOpenInfo}
        >
          Команда проекта
        </div>
        <FooterInfoHoverBlock closeInfo={handleClickCloseInfo} />
        <div className={styles.infoHoverBlockJoin}>Совместный проект</div>
        <div
          className={`${styles.advertising} ${
            active2 ? styles.activeAdvertisingBlock : ''
          }`}
          onClick={handleClickOpenAdvertising}
        >
          Реклама. ПАО «ПИК-специализированный застройщик»
        </div>
        <FooterAdvertisingHover
          closeAdvertising={handleClickCloseAdvertising}
        />
        <div className={styles.infoHoverBlockAd}>
          © АО «РОСБИЗНЕСКОНСАЛТИНГ», 1995–2023
        </div>
      </div>
      <div className={styles.shareBlock}>
        <div className={styles.heartImage}>
          <HeartLogo />
        </div>
        <div className={styles.shareHoverBlock}>
          <p>Поделиться:</p>
          <button onClick={() => handleShareClick('vk')}>vkontakte</button>
          <button onClick={() => handleShareClick('ok')}>odnoklassniki</button>
          <button onClick={() => handleShareClick('tg')}>telegram</button>
        </div>
      </div>
    </div>
  )
}
