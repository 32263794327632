import React from 'react'

export const RedLogo = () => {
  return (
    <svg
      width='78'
      height='24'
      viewBox='0 0 78 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 0H23.3342V24H16.6188V5.95119H6.71782V24H0V0ZM43.9183 0H49.8861V24H43.1708V10.1122L32.4257 24H26.4604V0H33.1683V13.8878L43.9183 0ZM66.0223 11.664L78 23.9949H69.0025L59.7252 14.3366V24H53.0099V0H59.7252V9.95246L67.8218 0H75.6609L66.0223 11.664Z'
        fill='#FC4C02'
      />
    </svg>
  )
}
