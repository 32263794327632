import React from 'react'
import { Link } from 'react-router-dom'

import { ArrowLeftIcon } from '../../icons/ArrowLeftIcon'
import st from './backButton.module.scss'
import { ButtonProps } from './BackButton.types'

export const BackButton = ({ url }: ButtonProps) => {
  return (
    <Link className={st.button} to={url} id='backButton'>
      <ArrowLeftIcon />
    </Link>
  )
}
