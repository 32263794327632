import {
  FETCH_SELECTIONS_FAILURE,
  FETCH_SELECTIONS_REQUEST,
  FETCH_SELECTIONS_SUCCESS,
} from './actionTypes'
import {
  FetchSelectionsFailure,
  FetchSelectionsFailurePayload,
  FetchSelectionsRequest,
  FetchSelectionsSuccess,
  FetchSelectionsSuccessPayload,
} from './types'

export const fetchSelectionsRequest = (
  typeSelection: string
): FetchSelectionsRequest => ({
  type: FETCH_SELECTIONS_REQUEST,
  typeSelection,
})

export const fetchSelectionsSuccess = (
  payload: FetchSelectionsSuccessPayload
): FetchSelectionsSuccess => ({
  type: FETCH_SELECTIONS_SUCCESS,
  payload,
})

export const fetchSelectionsFailure = (
  payload: FetchSelectionsFailurePayload
): FetchSelectionsFailure => ({
  type: FETCH_SELECTIONS_FAILURE,
  payload,
})
