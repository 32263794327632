export const spbMetroStations = [
  {
    local_name: 'Обводный канал',
    line_color: '#702884',
    line_name: 'Фрунзенско-Приморская',
  },
  {
    local_name: 'Девяткино',
    line_color: '#D6083B',
    line_name: 'Кировско-Выборгская',
  },
  {
    local_name: 'Улица Дыбенко',
    line_color: '#EA7125',
    line_name: 'Лахтинско-Правобережная',
  },
  {
    local_name: 'Балтийская',
    line_color: '#D6083B',
    line_name: 'Кировско-Выборгская',
  },
  {
    local_name: 'Приморская',
    line_color: '#009A49',
    line_name: 'Невско-Василеостровская',
  },
  {
    local_name: 'Ладожская',
    line_color: '#EA7125',
    line_name: 'Лахтинско-Правобережная',
  },
]
