import {
  FETCH_OBJECTS_FAILURE,
  FETCH_OBJECTS_REQUEST,
  FETCH_OBJECTS_SUCCESS,
} from './actionTypes'
import {
  FetchObjectsFailure,
  FetchObjectsFailurePayload,
  FetchObjectsRequest,
  FetchObjectsSuccess,
  FetchObjectsSuccessPayload,
} from './types'

export const fetchObjectsRequest = ({
  city,
  rooms,
  priceFrom,
  priceTo,
}: any): FetchObjectsRequest => ({
  type: FETCH_OBJECTS_REQUEST,
  city,
  rooms,
  priceFrom,
  priceTo,
})

export const fetchObjectsSuccess = (
  payload: FetchObjectsSuccessPayload
): FetchObjectsSuccess => ({
  type: FETCH_OBJECTS_SUCCESS,
  payload,
})

export const fetchObjectsFailure = (
  payload: FetchObjectsFailurePayload
): FetchObjectsFailure => ({
  type: FETCH_OBJECTS_FAILURE,
  payload,
})
