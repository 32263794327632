import { motion } from 'framer-motion'
import React from 'react'
import ReactGA from 'react-ga'
import { IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import ym from 'react-yandex-metrika'

import { getAnimationFourParams } from '../../../helpers/animations'
import styles from './articles.module.scss'
import { ArticlesTypes } from './Articles.types'

export const Articles = ({
  secondArticle,
  thirdArticle,
  mainPage,
}: ArticlesTypes) => {
  const navigate = useNavigate()

  const articleClick = (name: string, article: string) => {
    ReactGA.ga('send', 'event', 'article', 'see also bottom', name)

    ym('93597994', 'reachGoal', 'see also event', {
      'see also event': {
        'article bottom': {
          name: name,
        },
      },
    })

    navigate(`/article/${article}`)
  }

  return (
    <div className={mainPage && styles.articleMainBlock}>
      <motion.div
        {...getAnimationFourParams()}
        className={styles.articlesWrapper}
        id='#articles'
      >
        <h2 className={styles.articlesTitle}>
          {mainPage ? 'Статьи' : 'Читайте также'}
        </h2>
        <div className={styles.articleCards}>
          {thirdArticle && (
            <button
              className={styles.selectionCard}
              onClick={() =>
                articleClick(
                  'Жизнь в комфортной среде: стоит ли покупать квартиру в готовом квартале',
                  '2'
                )
              }
            >
              <div className={styles.selectionImg2} />
              <div className={styles.cardDescription}>
                <p>
                  Жизнь в&nbsp;комфортной среде: стоит&nbsp;ли покупать квартиру
                  в&nbsp;готовом квартале
                </p>
                <div className={styles.cardBtn}>
                  Читать <IoIosArrowForward />
                </div>
              </div>
            </button>
          )}
          {secondArticle && (
            <button
              className={styles.selectionCard}
              onClick={() =>
                articleClick(
                  'Город для детей: детство и родительство в современных жилых кварталах',
                  '3'
                )
              }
            >
              <div className={styles.selectionImg1} />
              <div className={styles.cardDescription}>
                <p>
                  Город для детей: детство и&nbsp;родительство
                  в&nbsp;современных жилых кварталах
                </p>
                <div className={styles.cardBtn}>
                  Читать <IoIosArrowForward />
                </div>
              </div>
            </button>
          )}
        </div>
        {/*<button className={styles.showAllBtn}>Показать ещё</button>*/}
      </motion.div>
    </div>
  )
}
