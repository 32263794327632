import {
  FETCH_CITIES_FAILURE,
  FETCH_CITIES_REQUEST,
  FETCH_CITIES_SUCCESS,
} from './actionTypes'
import {
  FetchCitiesFailure,
  FetchCitiesFailurePayload,
  FetchCitiesRequest,
  FetchCitiesSuccess,
  FetchCitiesSuccessPayload,
} from './types'

export const fetchCitiesRequest = (): FetchCitiesRequest => ({
  type: FETCH_CITIES_REQUEST,
})

export const fetchCitiesSuccess = (
  payload: FetchCitiesSuccessPayload
): FetchCitiesSuccess => ({
  type: FETCH_CITIES_SUCCESS,
  payload,
})

export const fetchCitiesFailure = (
  payload: FetchCitiesFailurePayload
): FetchCitiesFailure => ({
  type: FETCH_CITIES_FAILURE,
  payload,
})
