import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import styles from './swiper.module.scss'
import { SwiperTypes } from './Swiper.types'

export const ImageSwiper = ({ firstImg, secondImg, thirdImg }: SwiperTypes) => {
  return (
    <div className={styles.articleSlider}>
      <div className={styles.swiperWrapper}>
        <Swiper
          slideToClickedSlide={true}
          breakpoints={{
            320: {
              width: 270,
              slidesPerView: 1,
              spaceBetween: 15,
            },
            768: {
              width: 670,
              slidesPerView: 1,
              spaceBetween: 15,
            },
            1024: {
              width: 829,
              slidesPerView: 1,
              spaceBetween: 35,
            },
            1376: {
              width: 829,
              slidesPerView: 1,
              spaceBetween: 50,
            },
            1600: {
              width: 829,
              slidesPerView: 1,
              spaceBetween: 50,
            },
          }}
        >
          {firstImg && (
            <SwiperSlide>
              <div className={styles.articleSliderImg}>
                <figure>
                  <img src={firstImg} alt={''} />
                </figure>
              </div>
            </SwiperSlide>
          )}
          {secondImg && (
            <SwiperSlide>
              <div className={styles.articleSliderImg}>
                <figure>
                  <img src={secondImg} alt={''} />
                </figure>
              </div>
            </SwiperSlide>
          )}
          {thirdImg && (
            <SwiperSlide>
              <div className={styles.articleSliderImg}>
                <figure>
                  <img src={thirdImg} alt={''} />
                </figure>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </div>
    </div>
  )
}
