import {
  FETCH_OBJECTS_FAILURE,
  FETCH_OBJECTS_REQUEST,
  FETCH_OBJECTS_SUCCESS,
} from './actionTypes'
import { ObjectsActions, ObjectsState } from './types'

const initialState: ObjectsState = {
  pending: false,
  objects: [],
  error: null,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const objectsReducer = (state = initialState, action: ObjectsActions) => {
  switch (action.type) {
    case FETCH_OBJECTS_REQUEST:
      return {
        ...state,
        pending: true,
      }
    case FETCH_OBJECTS_SUCCESS:
      return {
        ...state,
        pending: false,
        objects: action.payload.objects,
        error: null,
      }
    case FETCH_OBJECTS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      }
    default:
      return {
        ...state,
      }
  }
}

export default objectsReducer
