import React from 'react'

export const FlashIcon = () => {
  return (
    <svg
      width='10'
      height='16'
      viewBox='0 0 10 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.87563 1.38883C8.04915 0.869729 7.38236 0.48923 7.02372 0.902692L0.717894 8.17237C0.437077 8.49611 0.667036 9 1.0956 9H3.72122C3.8581 9 3.95453 9.13442 3.91067 9.26408L2.10475 14.603C1.92921 15.1219 2.59577 15.5047 2.95549 15.0915L9.27886 7.82831C9.56055 7.50476 9.33075 7 8.90176 7H6.27773C6.14115 7 6.04475 6.86613 6.08805 6.7366L7.87563 1.38883Z'
        fill='#FC4C02'
      />
    </svg>
  )
}
