import {
  FETCH_SELECTIONS_FAILURE,
  FETCH_SELECTIONS_REQUEST,
  FETCH_SELECTIONS_SUCCESS,
} from './actionTypes'
import { SelectionsActions, SelectionsState } from './types'

const initialState: SelectionsState = {
  pending: false,
  selections: { items: [] },
  error: null,
}
// eslint-disable-next-line @typescript-eslint/default-param-last
const selectionsReducer = (state = initialState, action: SelectionsActions) => {
  switch (action.type) {
    case FETCH_SELECTIONS_REQUEST:
      return {
        ...state,
        pending: true,
      }
    case FETCH_SELECTIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        selections: action.payload.selections,
        error: null,
      }
    case FETCH_SELECTIONS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      }
    default:
      return {
        ...state,
      }
  }
}

export default selectionsReducer
