import React from 'react'
import { useScrollTracker } from 'react-scroll-tracker'

import Main from '../../components/Main/Main'
import { Layout } from '../../components/UI/Layout/Layout'
import { gaScrollPercent } from '../../hooks/usePageViews'

export const MainPage = () => {
  useScrollTracker([25, 50, 75, 100], ({ scrollY }) => {
    gaScrollPercent(scrollY)
  })

  return (
    <Layout>
      <Main />
    </Layout>
  )
}
