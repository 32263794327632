import { motion } from 'framer-motion'
import React, { useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import ym from 'react-yandex-metrika'

import { selectionCardData } from '../../../consts/selectionCardData'
import { getAnimationFourParams } from '../../../helpers/animations'
import { SelectionCard } from '../../SelectionCard/SelectionCard'
import styles from './selections.module.scss'

export const Selections = () => {
  const [openCards, setOpenCards] = useState(3)
  const reverseData = useMemo(() => [...selectionCardData].reverse(), [])

  const handleCardClick = (name: string) => {
    ReactGA.ga('send', 'event', 'main', 'collection click', name)
    ym('93597994', 'reachGoal', 'collection click', {
      'collection click': {
        'collection name': name,
      },
    })
  }
  const openCardsClick = (i: number) => {
    setOpenCards(openCards + i)
    ReactGA.ga('send', 'event', 'main', 'collections top list', 'show')
    ym('reachGoal', 'collections list click', {
      'collections list click': {
        main: {
          top: {
            'click type': 'show',
          },
        },
      },
    })
  }
  const closeCard = () => {
    setOpenCards(3)
    return reverseData
    ReactGA.ga('send', 'event', 'main', 'collections bottom list', 'hide')
    ym('reachGoal', 'collections list click', {
      'collections list click': {
        main: {
          bottom: {
            'click type': 'hide',
          },
        },
      },
    })
  }

  return (
    <motion.div
      {...getAnimationFourParams}
      className={styles.selectionsWrapper}
      id='#selections'
    >
      <h2 className={styles.selectionsTitle}>Подборки</h2>
      <div className={styles.selectionCards}>
        {reverseData?.slice(0, openCards).map(item => (
          <SelectionCard
            linkTo={item.id}
            cardClick={() => handleCardClick(item.title)}
            title={item.title}
            description={item.description}
            image={item.image}
            key={item.id}
          />
        ))}
      </div>
      {openCards === 3 ? (
        <button
          className={styles.showAllBtn}
          onClick={() => openCardsClick(reverseData?.length)}
        >
          Показать весь список
        </button>
      ) : (
        <button className={styles.showAllBtn} onClick={closeCard}>
          Скрыть весь список
        </button>
      )}
    </motion.div>
  )
}
