import React from 'react'

import styles from '../Footer/footer.module.scss'

export const InfoLogo = () => {
  return (
    <svg
      className={styles.infoLogo}
      width='45'
      height='45'
      viewBox='0 0 45 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1'
        y='1'
        width='43'
        height='43'
        rx='21.5'
        stroke='black'
        strokeWidth='2'
      />
      <path
        d='M22.4632 14.884C23.2282 14.884 23.8657 14.2465 23.8657 13.4815C23.8657 12.7165 23.2282 12.079 22.4632 12.079C21.6982 12.079 21.0607 12.7165 21.0607 13.4815C21.0607 14.2465 21.6982 14.884 22.4632 14.884ZM21.4432 31H23.5597V17.6635H21.4432V31Z'
        fill='black'
      />
    </svg>
  )
}
