export const getAnimationParams = (x: number) => {
  return {
    initial: { x: x, opacity: 0 },
    whileInView: { opacity: 1 },
    animate: { x: 0 },
    transition: { duration: 1, delay: 0.5 },
    viewport: { once: true },
  }
}

export const getAnimationFourParams = () => {
  return {
    initial: { opacity: 0 },
    whileInView: { opacity: 1 },
    transition: { duration: 1 },
    viewport: { once: true },
  }
}
