import { motion } from 'framer-motion'
import React from 'react'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ym from 'react-yandex-metrika'

import investmentImg from '../../../assets/images/selection1.jpg'
import { getAnimationFourParams } from '../../../helpers/animations'
import { getSelections } from '../../../store/selections/selectors'
import { Button } from '../../UI/Button/Button'
import { Table } from '../../UI/Table/Table'
import styles from './apartmentsTop.module.scss'
import { ApartsmentsTopTypes } from './ApartsmentsTop.types'

export const ApartmentsTop = ({ withoutTable }: ApartsmentsTopTypes) => {
  const navigate = useNavigate()
  const selections = useSelector(getSelections)

  return (
    <motion.div
      {...getAnimationFourParams()}
      className={styles.apartmentsWrapper}
      id='#investments'
    >
      <div className={styles.investmentsBlock}>
        <div className={styles.investmentsBlockImg}>
          <img src={String(investmentImg)} alt='investments' />
        </div>
        <div className={styles.investmentsDescription}>
          <h3>Квартиры в&nbsp;готовых проектах</h3>
          <p>Дома с&nbsp;инфраструктурой для жизни</p>
          <Button
            content='Интересно'
            arrowRight
            onClick={() => {
              ReactGA.ga(
                'send',
                'event',
                'main',
                'collection click',
                'Квартиры в готовых проектах'
              )
              ym('reachGoal', 'collection click', {
                'collection click': {
                  'collection name': 'Квартиры в готовых проектах',
                },
              })
              navigate('/collections/1')
            }}
          />
        </div>
      </div>
      {!withoutTable && (
        <Table items={selections.items} isFirstSelection position='top' />
      )}
    </motion.div>
  )
}
