import React from 'react'

export const HoverIcon = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.0358 3.64115L26.3183 3.64115L26.3183 10.9236'
        stroke='white'
        strokeWidth='2'
      />
      <path
        d='M14.6649 15.3243L25.6108 4.37836'
        stroke='white'
        strokeWidth='2'
      />
      <path
        d='M14.6649 3.64865H5.17845C3.96939 3.64865 2.98926 4.62878 2.98926 5.83784V24.8108C2.98926 26.0199 3.96939 27 5.17845 27H24.1514C25.3605 27 26.3406 26.0199 26.3406 24.8108V15.3243'
        stroke='#F4F4F3'
        strokeWidth='2'
      />
    </svg>
  )
}
