import stations from 'metrostations'

import { spbMetroStations } from '../consts/spbMetroStations'

export const getColorByStationName = (stationName: string) => {
  const str1 = stationName.toLowerCase().replace(/[ёе]/g, 'е')
  const station = stations?.Moscow?.Stations?.find(
    s => s.local_name.toLowerCase().replace(/[ёе]/g, 'е') === str1
  )
  const stationSpb = spbMetroStations?.find(
    s => s.local_name.toLowerCase().replace(/[ёе]/g, 'е') === str1
  )
  const line = stations?.Moscow?.Lines?.find(s => s.id === station?.line)
  if (stationName === 'Аминьевская') {
    return '#83C1C1'
  }
  if (stationName === 'Потапово') {
    return '#cf2028'
  }
  return line?.color || stationSpb?.line_color
}

export const pluralize = (
  number: number,
  one: string,
  few: string,
  many: string,
  withoutNumber?: boolean
) => {
  const num = Number(number)
  if (num % 10 === 1 && num % 100 !== 11) {
    return withoutNumber ? `${one}` : `${num} ${one}`
  } else if (
    num % 10 >= 2 &&
    num % 10 <= 4 &&
    (num % 100 < 10 || num % 100 >= 20)
  ) {
    return withoutNumber ? `${few}` : `${num} ${few}`
  } else {
    return withoutNumber ? `${many}` : `${num} ${many}`
  }
}

export const formatNumberWithThousands = (number: string) => {
  return number
    .toString()
    .split(' ')
    .join('')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const formatStringWithThousands = (str: string) => {
  str.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
