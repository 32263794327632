import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { scroller } from 'react-scroll'

import image from '../../assets/images/aboutProject.jpeg'
import { fetchSelectionsRequest } from '../../store/selections/actions'
import { AboutProject } from '../AboutProject/AboutProject'
import { HighCeilings } from '../Announcements/HighCeilings/HighCeilings'
import { LifeWest } from '../Announcements/LifeWest/LifeWest'
import { NearParks } from '../Announcements/NearParks/NearParks'
import { Articles } from '../Article/Articles/Articles'
import { Container } from '../Container/Container'
import { NavMenu } from '../NavMenu/NavMenu'
import { Feedback } from './Feedback/Feedback'
import styles from './main.module.scss'
import { NewBuildings } from './newBuildings/NewBuildings'
import { ProgramsPik } from './ProgramsPIK/ProgramsPIK'
import { Selections } from './Selections/Selections'

const Main: FC = () => {
  const { hash } = useLocation()
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSelectionsRequest('8-9'))
  }, [])

  useEffect(() => {
    if (hash) {
      scroller.scrollTo(hash, {
        duration: 500,
        delay: 0,
        offset: -70,
      })
      location.hash = ''
    }
  }, [hash])

  return (
    <div className={styles.mainWrapper}>
      <Container>
        <NavMenu />
        <AboutProject
          title='Пространство выбора'
          text='Какие квартиры стоят вашего внимания прямо сейчас — для себя или инвестиций'
          image={image}
          mainPage
        />
        <LifeWest />
        <NewBuildings />
        <NearParks />
        <Selections />
        {/*<Articles secondArticle thirdArticle mainPage />*/}
        <ProgramsPik pageType='main' />
        <Feedback />
      </Container>
    </div>
  )
}

export default Main
