import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { ERoutePath } from '../../consts/appRouter'
import {
  ArticlePage1,
  ArticlePage2,
  ArticlePage3,
  CollectionsPage,
  MainPage,
} from '../../pages'

export const AppRouter = () => {
  return (
    <Routes>
      <Route index element={<MainPage />} />
      {/*<Route path={ERoutePath.ARTICLES} element={<ArticlePage />} /> */}
      <Route path={ERoutePath.ARTICLES1} element={<ArticlePage1 />} />
      <Route path={ERoutePath.ARTICLES2} element={<ArticlePage2 />} />
      <Route path={ERoutePath.ARTICLES3} element={<ArticlePage3 />} />
      <Route path={ERoutePath.COLLECTIONS} element={<CollectionsPage />} />
      {/* страница ошибки перекидываем на главную */}
      <Route path='*' element={<MainPage />} />
    </Routes>
  )
}
